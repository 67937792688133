const languages: any = {
    VND: 'vi-VN',
    USD: 'en-US'
}

export const formatMoney = (number: string) => {
    return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatMoneyToNumber = (number: string) => {
    return number.replace(/\,/g, '')
}

export const viewShortMoney = (number: string) => {
    const money = formatMoneyToNumber(number)
    if (money.length >= 10) {
        return `${formatMoney((parseFloat(money) / 1000000000).toFixed(2).toString())} B`
    }
    if (money.length >= 7) {
        return `${formatMoney((parseFloat(money) / 1000000).toFixed(2).toString())} M`
    }
    if (money.length >= 4) {
        return `${formatMoney((parseFloat(money) / 1000).toFixed(2).toString())} K`
    }
    return `${formatMoney(money)}`
}

export const formatCurrency = (numStr: any, currency: string) => {
    if (numStr === '') return ''
    const lang = languages[currency]

    return new Intl.NumberFormat(lang ?? 'en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
    }).format(numStr)
}