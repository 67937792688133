export default class TechnicianServiceModel {
  public technicianId!: number
  public serviceId!: number
  public createdAt!: string

  constructor(data: any) {
    if (data) {
      this.technicianId = data.technician_id
      this.serviceId = data.service_id
      this.createdAt = data.created_at
    }
  }
}