import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    // dateBox
    dateBox: {
        display: 'inline-block',
        padding: '10px',
        border: '1px solid #dfe3e9',
        borderRadius: '8px',
        width: '75px',
        marginRight: '15px',
        textAlign: 'center',
    },

    dateBoxActive: {
        backgroundColor: '#2245a9',
        color: '#ffffff',
    },

    dateNumber: {
        fontSize: '18px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },

    dateName: {
        fontSize: '16px',
        fontWeight: '300',
        textTransform: 'uppercase',
    },


})

export default useStyles