import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { StaffAPI } from 'stores/apis';
import StaffModel from 'stores/models/staff.models';

export function* getStaffWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(StaffAPI.getStaff, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_STAFF_SUCCESS, payload: new StaffModel(response.data.data) })
    } else {
      yield put({ type: actions.GET_STAFF_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_STAFF_FAILED })
  }
}

function* getStaffWatcher() {
  yield takeLatest(actions.GET_STAFF, getStaffWorker)
}

export default getStaffWatcher