import { useDispatch, useSelector } from "react-redux";
import {
  BookingStatusColor,
  BookingStatusNames,
} from "stores/models/booking.models";
import { dateDiff, getStartOfDayTz } from "utils/times";

import images from "assets";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ReduxState } from "stores/models";
import BookingServiceModel from "stores/models/booking-service.models";
import StaffModel from "stores/models/staff.models";
import { FaStar } from "react-icons/fa";
import { Colors } from "constants/colors.constants";
import { Stars } from "components";
import useStyles from "./styles";

interface InitState {
  isShowMenu: any;
  isShowMenuEdit: any;
}

const BookingCard = (props: any) => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const styles = useStyles();
  const { refCode } = useParams();

  const { booking } = props;

  const { salon, timezones, technicians } = useSelector(
    (state: ReduxState) => ({
      salon: state.salons.salon,
      timezones: state.app.timezones,
      technicians: state.staffs.technicians,
    })
  );

  const salonTz: any = timezones.find((item) => item.id === salon?.timezoneId);

  const [state, setState] = useState<InitState>({
    isShowMenu: [],
    isShowMenuEdit: [],
  });

  const handleStateChange = (value: any, field: string) =>
    setState((prevState) => ({ ...prevState, [field]: value }));

  if (!booking && !salonTz) return null;

  const goBookingDetail = (booking: any) => {
    navigate(`/${refCode}/bookings/${booking?.id}`);
  };

  const remainingTime =
    dateDiff(
      booking?.startDateTime,
      getStartOfDayTz(salonTz?.timezone, "YYYY-MM-DD HH:mm:ss"),
      salonTz?.timezone,
      "hours"
    ) > 0
      ? dateDiff(
          booking?.startDateTime,
          getStartOfDayTz(salonTz?.timezone, "YYYY-MM-DD HH:mm:ss"),
          salonTz?.timezone,
          "hours"
        )
      : 0;

  const bookingServicesHasTech: BookingServiceModel =
    booking?.bookingServices?.find(
      (bookingServices: BookingServiceModel) => bookingServices.technicianId > 0
    );
  const technician: any = technicians?.find(
    (technician: StaffModel) =>
      technician.id === bookingServicesHasTech?.technicianId
  );

  const stars = Array(5).fill(0);
  return (
    <div
      className={styles.bookingListItem}
      key={booking?.id}
      onClick={() => goBookingDetail(booking)}
    >
      <div className={styles.bookingLeftInfo}>
        <div className={styles.customerAvatar}>
          <img
            className={styles.customerAvatarImg}
            src={images.logo()}
            alt={booking.name}
            width="100%"
            height="100%"
          />
        </div>
        <div className={styles.duration}>
          {booking.startDateTime
            ? moment(booking.startDateTime).format("HH:mm")
            : ""}
        </div>
        <div className="">
          {booking.startDateTime
            ? moment(booking.startDateTime).format("DD/MM/YYYY")
            : ""}
        </div>
      </div>
      <div className={styles.bookingRightInfo}>
        <div
          className={styles.rectangle}
          style={{
            backgroundColor: BookingStatusColor[booking?.status]?.recColor,
          }}
        ></div>
        <div className={styles.groupBookingInfo}>
          <div className={styles.bookingTechRow}>
            <div className={styles.groupTechName}>
              <div className={styles.techAvatar}>
                <img
                  className={styles.techAvatarImg}
                  src={images.logo()}
                  alt={booking.name}
                  width="100%"
                  height="100%"
                />
              </div>
              <div className={styles.bookingTechName}>{booking?.name}</div>
            </div>

            <div className={styles.techPhone}>{booking?.phone}</div>
          </div>
          {booking?.rate.value && (
            <Stars rateValue={booking.rate.value} size={12} marginRight={5} />
          )}
          <div className={styles.bookingNote}>{booking?.note}</div>
        </div>
      </div>
      <div
        className={styles.bookingStatus}
        style={{
          backgroundColor: BookingStatusColor[booking?.status]?.tagColor,
        }}
      >
        <span
          className={styles.txtBookingStatus}
          style={{ color: BookingStatusColor[booking?.status]?.txtTagColor }}
        >
          {t(BookingStatusNames[booking?.status])}
        </span>
      </div>
    </div>
  );
};

export default BookingCard;
