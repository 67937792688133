export default class BookingServiceModel {
  public id!: number
  public bookingId!: number
  public serviceId!: number
  public technicianId!: number
  public name!: string
  public price!: number
  public duration!: number
  public createdAt!: string
  public updatedAt!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.bookingId = data.booking_id
      this.serviceId = data.service_id
      this.technicianId = data.technician_id
      this.name = data.name
      this.price = data.price
      this.duration = data.duration
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
    }
  }
}