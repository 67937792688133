import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { BookingAPI } from 'stores/apis';
import BookingModel from 'stores/models/booking.models';

export function* getBookingsWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(BookingAPI.getBookings, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_BOOKINGS_SUCCESS, payload: response.data.data.map((v: any) => new BookingModel(v)) })
    } else {
      yield put({ type: actions.GET_BOOKINGS_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_BOOKINGS_FAILED })
  }
}

function* getBookingsWatcher() {
  yield takeLatest(actions.GET_BOOKINGS_REQUEST, getBookingsWorker)
}

export default getBookingsWatcher