import { Colors } from 'constants/colors.constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    viewInfo: {
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '15px',        
        marginBottom: '10px',
        borderRadius: '16px',
        backgroundColor: '#F3F6F6',
    },

    viewDefault: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    txtFullName: {
        flex: 1,
        fontWeight: 'bold',
        color: '#3C3C3E',
        flexWrap: 'wrap',
        lineHeight: '20px',
    },

    txtDate: {
        flex: 1,
        fontWeight: '600',
        color: '#3C3C3E',
        flexWrap: 'wrap',
        lineHeight: '20px',
    },

    txtPhone: {
        flex: 1,
        color: '#3C3C3E',
        flexWrap: 'wrap',
        lineHeight: '20px',
    },

    txtDescription: {
        color: '#3C3C3E',
    },

    viewGroupBtn: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    viewIconHeader: {
        width: '40px',
        height: '40px',
        borderRadius: '8px',
        borderColor: '#578688',
        borderWidth: 1,
        borderStyle: 'dashed',
        alignItems: 'center',
        justifyContent: 'center',
    },

    iconHeader: {
        color: Colors.PRIMARY
    },

    iconMore: {
        marginLeft: '10px',
    },
})

export default useStyles