// eslint-disable-next-line no-use-before-define

import './pagination.css';

import * as actions from 'stores/actions';

import { Loading, Wrapper } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from 'assets/';
import EmptyState from 'components/empty-state';
import HeaderLogout from 'components/header-logout';
import { envConfigs } from 'configs/env.config';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'stores/models';
import { localStorageUtils } from 'utils/index';
import { modalRef } from 'utils/refs';
import BookingCard from './components/booking-card';
import useStyles from './styles';
import { LocalStorageValueTypes } from 'utils/localStorage';

const LogoutModalContent = ({ t, styles }: any) => {
  const dispatch = useDispatch()

  const logout = () => {
    dispatch({ type: actions.LOG_OUT })
    localStorageUtils.clear()
    window.location.href = `${envConfigs.WEB_URL}/login`
  }

  const onCloseModal = () => modalRef?.current?.close()

  return (
    <div>
      <h6>{t('are_you_sure_logout')}</h6>
      <div className={styles.logoutBtns}>
        <button onClick={onCloseModal} className={styles.btnNo}>
          {t('cancel')}
        </button>
        <button onClick={logout} className={styles.btnYes}>
          {t('logout')}
        </button>
      </div>
    </div>
  )
}

const Bookings = () => {
  const dispatch = useDispatch()
  const { t }: any = useTranslation()
  const styles = useStyles()
  const navigate: any = useNavigate()
  const limit = 10

  const { refCode } = useParams()

  const { salon, bookings, user } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    bookings: state.bookings.bookings,
    user: state.users.user,
  }))

  const [state, setState] = useState({
    loading: false,
    bookingList: [],
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))

  useEffect(() => {
    if (refCode) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.SalonRefCode, refCode)
    }
  }, [refCode])

  useEffect(() => {
    document.title = salon?.name ? salon.name : `KAY CLINIC`
  }, [salon])

  useEffect(() => {
    dispatch({ type: actions.SET_MENU, payload: 3 })
  }, [])

  useEffect(() => {
    if (user && refCode) {
      dispatch({ type: actions.GET_BOOKINGS_REQUEST, payload: { salonRefCode: refCode } })
    }
  }, [user, refCode])

  useEffect(() => {
    if (bookings?.length > 0) {
      handleStateChange({ bookingList: bookings.sort((a: any, b: any) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()) })
    }
  }, [bookings])

  const onOpenLogoutModal = () => {
    modalRef?.current?.open({
      content: <LogoutModalContent t={t} styles={styles} />,
    })
  }

  return (
    <Wrapper>
      {state.loading && <Loading />}

      <HeaderLogout />

      <div className={styles.sectionList}>
        <div className={styles.sectionListHeader}>
          <div className={styles.sectionListHeaderTitle}>
            <p>{t('booking')}</p>
          </div>
        </div>

        <div className={styles.bookingList}>
          {state.bookingList.length > 0 ?
            <>
              {state.bookingList?.map((booking: any) => (
                <BookingCard key={booking.id} booking={booking} />
              ))}
            </>
            :
            <EmptyState imgUrl={images.bookingEmpty()} text="No bookings" />
          }
        </div>
      </div>
    </Wrapper>
  )
}

export default Bookings
