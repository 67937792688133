export default class BookingRateModel {
  public id!: number;
  public bookingId!: number;
  public content!: string;
  public createdAt!: number;
  public updatedAt!: number;
  public salonId!: number;
  public userId!: number;
  public value!: number;

  constructor(data: any) {
    if (data) {
      this.id = data?.id;
      this.bookingId = data?.booking_id;
      this.content = data?.content;
      this.createdAt = data?.created_at;
      this.updatedAt = data?.updated_at;
      this.salonId = data?.salon_id;
      this.userId = data?.user_id;
      this.value = data?.value;
    }
  }
}
