import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    // timeBox
    timeBox: {
        display: 'inline-block',
        padding: '8px',
        border: '1px solid #dfe3e9',
        borderRadius: '8px',
        width: '75px',
        margin: '5px',
        textAlign: 'center',
    },

    timeBoxActive: {
        backgroundColor: '#2245a9',
        color: '#ffffff',
    },

    timeNumber: {
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
})

export default useStyles