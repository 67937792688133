import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    rowGroupService: {
        borderBottom: '1px dashed #ebeaea'
    },

    groupTitle: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '10px 0',
    },

    groupTitleName: {
        flex: 1,
    },

    groupTitleIcon: {
        marginRight: '15px',
    }
})

export default useStyles
