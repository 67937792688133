import * as actions from 'stores/actions'

import { call, put, takeLatest } from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { TimezoneAPI } from 'stores/apis'

export function* getTimezonesWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(TimezoneAPI.getTimezones)

    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_TIMEZONES_SUCCESS, payload: response.data.data })
    } else {
      yield put({ type: actions.GET_TIMEZONES_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_TIMEZONES_FAILED })
  }
}

function* getTimezonesWatcher() {
  yield takeLatest(actions.GET_TIMEZONES_REQUEST, getTimezonesWorker)
}

export default getTimezonesWatcher