import * as actions from 'stores/actions'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import images from 'assets'
import { AxiosResponse } from 'axios'
import { Wrapper } from 'components'
import EmptyState from 'components/empty-state'
import HeaderLogout from 'components/header-logout'
import { TechnicianAvatar } from 'components/technician-avatar'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { IoCalendarClearOutline, IoFileTrayFullOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { BookingAPI } from 'stores/apis'
import { ReduxState } from 'stores/models'
import { BookingType } from 'stores/models/booking.models'
import { SalonStaffRole } from 'stores/models/staff.models'
import { localStorageUtils } from 'utils/index'
import { LocalStorageValueTypes } from 'utils/localStorage'
import { modalRef } from 'utils/refs'
import { validatePhone } from 'utils/string'
import { convertTzToUtc } from 'utils/times'
import BookingServiceCard from './components/booking-service-card'
import ChooseDateCard from './components/choose-date-card'
import ChooseServices from './components/choose-services/index'
import ChooseTechnician from './components/choose-technician'
import ChooseTimeCard from './components/choose-time-card'
import useStyles from './styles'
interface StateInterface {
  loading: boolean,
  services: any[],
  date: any,
  time: any,
  technician: any,
  dateList: any[],
  timeList: any[],
  note: any,
}

const SuccessModal = (props: any) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate: any = useNavigate()
  const refCode = localStorageUtils.getLocalStorage(LocalStorageValueTypes.SalonRefCode)

  const { booking } = props

  const onBack = () => {
    navigate(`/${refCode}/bookings`)
    modalRef?.current?.close()
  }

  const goBookingDetail = (booking: any) => {
    navigate(`/${refCode}/bookings/${booking?.id}`)
    modalRef?.current?.close()
  }

  return (
    <div className={styles.confirmContent}>
      <div>{t('booking_successfully')}</div>
      <div className={styles.groupButton}>
        <button onClick={onBack} className={styles.buttonCancel}>
          {t('back')}
        </button>

        <button onClick={() => goBookingDetail(booking)} className={styles.buttonConfirm}>
          {t('go_detail')}
        </button>
      </div>
    </div>
  )
}

const Confirm = (props: any) => {
  const styles = useStyles()

  const { message, callback, cancelButtonText, confirmButtonText, t } = props

  const onCloseModal = () => modalRef?.current?.close()

  return (
    <div className={styles.confirmContent}>
      <h6>{message}</h6>
      <div className={styles.groupButton}>
        <button onClick={onCloseModal} className={styles.buttonCancel}>
          {cancelButtonText ?? t('cancel')}
        </button>
        {callback &&
          <button onClick={callback} className={styles.buttonConfirm}>
            {confirmButtonText ?? t('confirm')}
          </button>
        }
      </div>
    </div>
  )
}

const UpsertBooking = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = useStyles()

  const { refCode, bookingId } = useParams()

  const { salon, technicians, user, timezones } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    technicians: state.staffs.technicians,
    user: state.users.user,
    timezones: state.app.timezones,
  }))

  const [state, setState] = useState<StateInterface>({
    loading: false,
    services: [],
    date: moment(),
    time: null,
    technician: [],
    dateList: Array.from({ length: 7 }, (_, i) => i++).map(i => moment().add(i, 'days')),
    timeList: [],
    note: ''
  })
  const handleStateChange = (value: string | any | boolean, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  useEffect(() => {
    if (refCode) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.SalonRefCode, refCode)
    }
  }, [refCode])

  useEffect(() => {
    dispatch({ type: actions.SET_MENU, payload: 2 })
  }, [])

  useEffect(() => {
    getInfo(salon?.id)
  }, [user, salon?.id])

  const getInfo = async (salonId: any) => {
    if (salonId) {
      dispatch({ type: actions.GET_TECHNICIANS, payload: { salonId, role: SalonStaffRole.Technician } })
      dispatch({ type: actions.GET_CATEGORIES, payload: { salonId } })
      dispatch({ type: actions.GET_SERVICES, payload: { salonId } })
      dispatch({ type: actions.GET_BOOKINGS_REQUEST, payload: { salonId } })
    }
  }

  useEffect(() => {
    if (user && salon && bookingId) {
      dispatch({ type: actions.GET_BOOKING_DETAIL, payload: bookingId })
    }
  }, [user, salon, bookingId])

  useEffect(() => {
    if (salon) {
      const focusDate = salon?.workingTime.find((item: any) => item.weekday === state?.date?.isoWeekday())

      if (focusDate) {
        const currentDate = moment().format('YYYY-MM-DD')
        const isSameDate = currentDate === state?.date?.format('YYYY-MM-DD')

        const dateTime = moment().add(30 - (moment().minute() % 30), "minutes").format('HH:mm');
        const currentTime = moment(dateTime, 'h:mm A')
        const greaterTime = currentTime >= moment(focusDate.from, 'h:mm A')

        let lastItem = isSameDate && greaterTime ? currentTime : moment(focusDate.from, 'h:mm A')
        const arr = []

        while (lastItem.isSameOrBefore(moment(focusDate.to, 'h:mm A').subtract(60, 'minutes'))) {
          arr.push(moment(lastItem, 'h:mm A'))
          lastItem = lastItem.add(30, 'minutes')
        }
        handleStateChange(arr, 'timeList')
      } else {
        handleStateChange([], 'timeList')
      }
    }
  }, [user, state.date, salon])

  if (!salon) return null
  const salonTz: any = timezones.find(item => item.id === salon?.timezoneId)

  const onCloseModal = () => {
    modalRef?.current?.close()
  }

  const openModalAddService = () => {
    modalRef?.current?.open({
      content: <ChooseServices callback={onSelectService} selected={state?.services} onCloseModal={onCloseModal} />,
    })
  }

  const onSelectService = async (services: any) => {
    handleStateChange(services, 'services')
    modalRef?.current?.close()
  }

  const openModalAddTechnician = () => {
    modalRef?.current?.open({
      content: <ChooseTechnician callback={onSelectTechnician} selected={state?.technician} servicesSelected={state.services} onCloseModal={onCloseModal} />,
    })
  }

  const onSelectTechnician = async (technician: any) => {
    handleStateChange(technician, 'technician')
    modalRef?.current?.close()
  }

  const onSelectDate = (date: any) => {
    handleStateChange(date, 'date')
  }

  const onSelectTime = (time: any) => {
    handleStateChange(time, 'time')
  }

  const onSuccessModal = (booking: any) => {
    modalRef?.current?.open({
      content: <SuccessModal booking={booking} />,
    })
  }

  const onSave = async () => {
    const { services, date, time, technician, note } = state;

    const phone = user?.phone || ''
    const name = user?.name || ''

    console.log('services', services);
    console.log('date', date);
    console.log('time', time);
    console.log('technician', technician);
    console.log('note', note);

    if (!validatePhone(phone)) {
      // AlertUtils.showError('Invalid phone number.')
      handleStateChange(false, 'loading')
      return
    }

    const s = services.map((item: any) => {
      const tId = technician
        ? technicians.find((t: any) => t.serviceIds.includes(item.id) && t.id === technician?.id)?.id
        : null

      return {
        sId: item?.id,
        tId
      }
    })

    const data = {
      name,
      phone,
      paxNumber: 1,
      salonId: salon?.id,
      startDateTime: convertTzToUtc(`${date.format('YYYY-MM-DD')} ${time.format('HH:mm:ss')}`, salon?.timezone?.timezone),
      type: BookingType.WebUser,
      services: JSON.stringify(s),
      userId: user?.id,
      note,
    }
    console.log("data", data)
    const response: AxiosResponse<any> | undefined = await BookingAPI.createBooking(data)

    if (response?.data?.status === 200) {
      onSuccessModal(response?.data?.data)
    } else {
      if (response?.data?.message) {
        modalRef?.current?.open({
          content: <Confirm message={response?.data?.message} t={t} />
        })
      } else {
        modalRef?.current?.open({
          content: <Confirm message={'Something went wrong'} t={t} />
        })
      }
    }
    handleStateChange(false, 'loading')
  }

  const disabled = state.loading || !state.services.length || !state.date || !state.time

  return (
    <Wrapper>
      <HeaderLogout />

      <div className={styles.sectionList}>
        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoFileTrayFullOutline size={20} />
            <span className={styles.label}>{t('services')}</span>
            <span className={styles.labelButton} onClick={openModalAddService}>{t('choose_service')}</span>
          </div>
          <div className={styles.rowItemContent}>
            {
              state?.services?.length > 0 ?
                state.services.map((service: any) => (
                  <BookingServiceCard key={service.id} item={service} />
                ))
                :
                <EmptyState imgUrl={images.bookingEmpty()} text={t('no_services_selected')} classNameImage={styles.noSelected} />
            }
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoCalendarClearOutline size={20} />
            <span className={styles.label}>{t('pick_date')}</span>
          </div>
          <div className={styles.rowItemContent}>
            <div className={styles.rowDate}>
              {
                state?.dateList?.length ?
                  state?.dateList?.map((day: any) => (
                    <ChooseDateCard key={day.format('DD')} selected={state.date} date={day} onSelect={onSelectDate} />
                  ))
                  :
                  <p>{t('salon_closed')}</p>
              }
            </div>
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoCalendarClearOutline size={20} />
            <span className={styles.label}>{t('pick_time')}</span>
          </div>
          <div className={styles.rowItemContent}>
            <div className={styles.rowTime}>
              {
                state?.timeList?.length ?
                  state?.timeList?.map((time: any) => (
                    <ChooseTimeCard key={time} selected={state.time} time={time} onSelect={onSelectTime} />
                  ))
                  :
                  <p>{t('salon_closed')}</p>
              }
            </div>
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoFileTrayFullOutline size={20} />
            <span className={styles.label}>{t('technician')}</span>
            {state?.services?.length > 0 && <span className={styles.labelButton} onClick={openModalAddTechnician}>Choose technician</span>}
          </div>
          <div className={styles.rowItemContent}>
            {
              state?.technician?.id ?
                <div className={styles.technicianCard}>
                  <div className={styles.techAvatar}>
                    <TechnicianAvatar avatarUrl={state?.technician?.id ? state?.technician?.avatarUrl() : null} technician={state.technician} style={styles.techAvatar} />
                  </div>
                  <div className={styles.f1}>
                    <span className={styles.serviceName}>{state.technician?.name}</span>
                  </div>
                </div>
                :
                <EmptyState imgUrl={images.technicianEmpty()} text={t('no_technician_selected')} classNameImage={styles.noSelected} />
            }
          </div>
        </div>

        <div className={styles.contentRow}>
          <div className={styles.rowLabel}>
            <IoCalendarClearOutline size={20} />
            <span className={styles.label}>{t('notes')}</span>
          </div>
          <div className={styles.rowItemContent}>
            <div className={styles.rowDate}>
              <textarea className={styles.textarea} value={state?.note} onChange={(e) => handleStateChange(e.target.value, 'note')}></textarea>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bookingFooter}>
        <button className={`${styles.btnBooking} ${disabled && styles.btnDisabled}`} onClick={!disabled ? onSave : undefined}>
          {t('booking')}
        </button>
      </div>

    </Wrapper>
  )
}

export default UpsertBooking
