import { BsCheck2, BsClock } from 'react-icons/bs';

import { useSelector } from 'react-redux';
import { ReduxState } from 'stores/models';
import { formatCurrency } from 'utils/currency';
import useStyles from './styles';

const ChooseServicesCard = (props: any) => {
  const styles = useStyles()

  const { salon } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
  }))

  const { service, selected, onSelected } = props

  const itemSelected = selected.find((s: any) => Number(s?.id) === Number(service?.id))

  const hour = Math.floor(service?.duration / 60) || 0
  const minute = service?.duration % 60 || 0

  return (
    <div className={`${styles.serviceInfo} ${itemSelected ? styles.techInfoSelected : ''}`} onClick={() => onSelected(service)}>
      <div className={styles.techName}>
        {service?.name}
      </div>
      <div className={styles.rowDurationPrice}>
        <div className={styles.durationBox}>
          <BsClock size={18} />
          {hour > 0 && <span className={styles.duration}>{hour}h</span>}
          {minute > 0 && <span className={styles.duration}>{minute}m</span>}
        </div>
        <div className={styles.priceBox}>
          {service?.refPrice > 0 && <span className={styles.refPrice}>({formatCurrency(service?.refPrice?.toString(), salon?.timezone?.currency)})</span>}
          <span className={styles.price}>{formatCurrency(service?.price?.toString(), salon?.timezone?.currency)}</span>
        </div>
      </div>
      {
        itemSelected ? <span className={styles.checked}><BsCheck2 /></span> : null
      }
    </div>
  )
}

export default ChooseServicesCard