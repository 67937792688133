export default class NotificationsModel {
  public id!: number
  public fromUserId!: number
  public userId!: number
  public type!: number
  public typeId!: number
  public salonId!: number
  public title!: string
  public body!: string
  public timestamp!: number
  public read!: boolean
  public data!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.fromUserId = data.from_user_id
      this.userId = data.user_id
      this.type = data.type
      this.typeId = data.type_id
      this.salonId = data.salon_id
      this.title = data.title
      this.body = data.body
      this.timestamp = data.timestamp
      this.read = data.read
      this.data = data.data
    }
  }
}