import gateway, { APIMethod, parseFormData } from "./gateway"

import { envConfigs } from 'configs'

const API_SERVER = envConfigs.SERVER_URL

export const getSalons = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons`, params)
}

export const createSalon = (data: any, files: any) => {
  const formData: any = parseFormData(data)

  files.forEach((f: any) => {
    if (f?.uri) {
      formData.append('files', f)
    }
  })

  return gateway.send(APIMethod.POST, `${API_SERVER}/salons`, formData, { 'Content-Type': 'multipart/form-data' })
}

export const updateSalon = (salonId: number, params: any, files: any) => {
  const formData: any = parseFormData(params)

  files.forEach((f: any) => {
    if (f?.uri) {
      formData.append('files', f)
    }
  })

  return gateway.send(APIMethod.PUT, `${API_SERVER}/salons/${salonId}`, formData, { 'Content-Type': 'multipart/form-data' })
}


export const getSalon = (salonId: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/${salonId}`)
}

export const getSalonWeb = (refCode: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/web-booking?refCode=${refCode}`)
}