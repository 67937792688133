import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  sectionHeader: {
    display: 'flex',
    padding: '15px 0',
    borderBottom: '1px solid #2245a9'
  },

  sectionImages: {
    display: 'flex',
    flexDirection: 'column',
  },

  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
    width: '70px',
  },

  avatarImage: {
    width: '100%',
    borderRadius: '50%',
    '@media (max-width: 900px)': {
      top: '-35px',
      width: '70px',
      height: '70px',
    },
  },

  sectionUserInfo: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: '15px'
  },

  userName: {
    color: '#2245a9',
    fontWeight: '600',
  },

  sectionLogout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  logout: {
    color: '#2245a9',
    textTransform: 'uppercase',
    marginLeft: '5px',
    fontWeight: 'bold'
  },

  //salon 
  salonAvatar: {
    width: '76px',
    height: '76px',
    borderRadius: '12px',
  },

  salonImage: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
  },

  salonName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },

  salonEmail: {
    display: 'flex',
    color: '#2245a9',
  },

  salonPhone: {
    display: 'flex',
    color: '#2245a9',
  },

  salonAddress: {
    display: 'flex',
    color: '#2245a9',
    cursor: 'pointer',
  },
})

export default useStyles