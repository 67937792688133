import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { CategoriesAPI } from 'stores/apis';
import CategoriesModel from 'stores/models/categories.models';

export function* getCategoriesWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(CategoriesAPI.getCategories, action.payload)
    if (response?.data?.status === 200) {      
      yield put({ type: actions.GET_CATEGORIES_SUCCESS, payload: response.data.data.map((v: any) => new CategoriesModel(v)) })
    } else {
      yield put({ type: actions.GET_CATEGORIES_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_CATEGORIES_FAILED })
  }
}

function* getCategoriesWatcher() {
  yield takeLatest(actions.GET_CATEGORIES, getCategoriesWorker)
}

export default getCategoriesWatcher