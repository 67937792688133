import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    // tech card    
    techInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0.5rem',
        border: '1px solid #ccc',
        borderRadius: '0.5rem',
        margin: '0.5rem 0',
        position: 'relative',
    },

    techInfoSelected: {
        border: '1px solid #2245a9',
    },


    techAvatar: {
        width: '60px',
        height: '60px',
        borderRadius: '8px',
        marginRight: '10px',
    },

    techAvatarImg: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
    },

    techName: {
        color: '#3C3C3E',
        fontWeight: '400'
    },

    checked: {
        display: 'flex',
        position: 'absolute',
        top: 5,
        right: 5,
        padding: '0.2rem',
        borderRadius: '50%',
        backgroundColor: '#2245a9',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '400',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #ccc',
    }
})

export default useStyles