
import useStyles from './styles';

const ChooseDateCard = (props: any) => {
  const styles = useStyles()
  const { date, selected, onSelect } = props

  return (
    <div className={`${styles.dateBox} ${date === selected && styles.dateBoxActive}`} onClick={() => onSelect(date)}>
      <div className={styles.dateNumber}>
        {date.format('DD')}
      </div>
      <div className={styles.dateName}>
        {date.format('ddd')}
      </div>
    </div>
  )
}

export default ChooseDateCard