import gateway, { APIMethod } from "./gateway";

import { envConfigs } from "configs";

const API_SERVER = envConfigs.SERVER_URL;

export const getBookings = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/user-bookings`, params);
};

export const getBooking = (bookingId: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/bookings/${bookingId}`);
};

export const createBooking = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/bookings`, params);
};

export const getBookingStatics = (params: any) => {
  return gateway.send(
    APIMethod.GET,
    `${API_SERVER}/bookings-statistics`,
    params
  );
};

export const updateBooking = (params: any) => {
  const { bookingId, ...query } = params;
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/bookings/${bookingId}`,
    query
  );
};

export const addBookingService = (params: any) => {
  const { bookingId, ...data } = params;
  return gateway.send(
    APIMethod.POST,
    `${API_SERVER}/bookings/${bookingId}/booking-services`,
    data
  );
};

export const updateBookingService = (params: any) => {
  const { bookingId, bookingServiceId, ...data } = params;
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/bookings/${bookingId}/booking-services/${bookingServiceId}`,
    data
  );
};

export const deleteBookingService = (
  bookingId: number,
  bookingServiceId: number
) => {
  return gateway.send(
    APIMethod.DELETE,
    `${API_SERVER}/bookings/${bookingId}/booking-services/${bookingServiceId}`
  );
};

export const addExtraService = (params: any) => {
  const { bookingId, ...data } = params;
  return gateway.send(
    APIMethod.POST,
    `${API_SERVER}/bookings/${bookingId}/extra-services`,
    data
  );
};

export const addBookingRate = (params: any) => {
  const { bookingId, ...data } = params;
  return gateway.send(
    APIMethod.POST,
    `${API_SERVER}/bookings/${bookingId}/rates`,
    data
  );
};

export const updateBookingRate = (params: any) => {
  const { bookingId, rateId, ...data } = params;
  return gateway.send(
    APIMethod.PUT,
    `${API_SERVER}/bookings/${bookingId}/rates/${rateId}`,
    data
  );
};
