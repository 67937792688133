import * as actions from '../actions';

import { ServicesState } from './../models/redux-state.models';

const initState: ServicesState = {
  loading: false,
  services: [],
  service: null
}

const services = (state = initState, action: any) => {
  switch (action.type) {

    case actions.GET_SERVICES:
      return {
        ...state,
        loading: true
      };
    case actions.GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload
      };
    case actions.GET_SERVICES_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.GET_SERVICE:
      return {
        ...state,
        loading: true
      };
    case actions.GET_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        service: action.payload
      };
    case actions.GET_SERVICE_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.LOG_OUT:
      return initState

    default:
      return state;
  }
};

export default services