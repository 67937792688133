import { envConfigs } from 'configs/env.config'
import images from 'assets'

export enum BookingCommentContentType {
  Comment = 1,
  Images = 2,
}
export default class BookingCommentModel {
  public id!: number
  public bookingId!: number
  public userId!: number
  public contents!: any
  public type!: number
  public createdAt!: string
  public updatedAt!: string
  public user!: any

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.bookingId = data.booking_id
      this.userId = data.user_id
      this.contents = data.contents
      this.type = data.type
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.user = data.user
    }
  }

  public get avatar(): string {
    if (this.user?.thumbnail) {
      return `${envConfigs.IMAGE_LINK}/users/${this.user?.thumbnail}`
    }
    return images.salonLogoDefault()
  }
}