export default class SalonWorkingTimeModel {
  public id!: number
  public salonId!: number
  public weekday!: number
  public from!: string
  public to!: string

  constructor(data: any) {
    if (data) {
      this.id = data?.id
      this.salonId = data?.salon_id
      this.weekday = data?.weekday
      this.from = data?.from
      this.to = data?.to
    }
  }
}