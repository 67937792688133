import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { BookingAPI } from 'stores/apis';
import BookingModel from 'stores/models/booking.models';

export function* getBookingWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(BookingAPI.getBooking, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_BOOKING_DETAIL_SUCCESS, payload: new BookingModel(response.data.data) })
    } else {
      yield put({ type: actions.GET_BOOKING_DETAIL_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_BOOKING_DETAIL_FAILED })
  }
}

function* getBookingWatcher() {
  yield takeLatest(actions.GET_BOOKING_DETAIL, getBookingWorker)
}

export default getBookingWatcher