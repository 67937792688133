const images = {
  logo: () => require('./images/logo.jpg'),
  logoKay: () => require('./images/kay/logo.png'),
  salonBannerDefault: () => require('./images/salon_banner_default.png'),
  salonLogoDefault: () => require('./images/salon_logo_default.png'),
  userAvatarDefault: () => require('./images/user_avatar_default.png'),
  technicianDefault: () => require('./images/technician_default.png'),
  customerDefault: () => require('./images/customer_default.png'),
  bookingEmpty: () => require('./images/booking_empty.png'),
  overviewEmpty: () => require('./images/overview_empty.png'),
  technicianEmpty: () => require('./images/technician_empty.png'),
  signupImage: () => require('./images/kay/logo.png'),
}

export default images