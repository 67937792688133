import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    //service card    
    serviceInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0.5rem',
        border: '1px solid #ccc',
        borderRadius: '0.5rem',
        margin: '0.5rem 0',
        position: 'relative',
        marginLeft: '20px'
    },

    techInfoSelected: {
        border: '1px solid #2245a9',
    },

    techName: {
        display: 'flex',
        color: '#3C3C3E',
        fontWeight: '700',
        padding: '0.5rem',
    },

    rowDurationPrice: {
        display: 'flex',
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    durationBox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        color: '#3C3C3E',
        padding: '0 0.5rem',
    },

    duration: {
        color: '#3C3C3E',
        fontWeight: '400',
        marginLeft: '0.5rem',
    },

    priceBox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        color: '#3C3C3E',
        fontWeight: '400',
        padding: '0 0.5rem',
        justifyContent: 'flex-end'
    },

    price: {
        color: '#3C3C3E',
        fontWeight: '600',
        marginLeft: '0.5rem',
    },

    refPrice: {
        color: '#3C3C3E',
        marginLeft: '0.5rem',
        textDecoration: 'line-through',
    },

    icon: {
        display: 'flex',
    },

    checked: {
        display: 'flex',
        position: 'absolute',
        top: 5,
        right: 5,
        padding: '0.2rem',
        borderRadius: '50%',
        backgroundColor: '#2245a9',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '400',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #ccc',
    }
})

export default useStyles