import gateway, { APIMethod, parseFormData } from "./gateway"

import { envConfigs } from 'configs'

const API_SERVER = envConfigs.SERVER_URL

export const getStaffs = (params: any) => {
  const { salonId, ...query } = params
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/${params.salonId}/staffs`, query)
}

export const createStaff = (salonId: number, params: any, files: any) => {
  const formData: any = parseFormData(params)
  if (files?.uri) {
    formData.append('files', files)
  }

  return gateway.send(APIMethod.POST, `${API_SERVER}/salons/${salonId}/staffs`, formData, { 'Content-Type': 'multipart/form-data' })
}

export const updateStaff = (userId: number, params: any, files: any) => {
  const formData: any = parseFormData(params)
  if (files?.uri) {
    formData.append('files', files)
  }

  return gateway.send(APIMethod.PUT, `${API_SERVER}/users/${userId}`, formData, { 'Content-Type': 'multipart/form-data' })
}

export const getStaff = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/${params.salonId}/staffs/${params.technicianId}`)
}

export const updateTechnicianServices = (salonId: number, id: number, data: any) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/salons/${salonId}/staffs/${id}/services`, data)
}

export const deleteTechnician = (salonId: number, id: number) => {
  return gateway.send(APIMethod.DELETE, `${API_SERVER}/salons/${salonId}/staffs/${id}`)
}

export const getStaffRole = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/${params.salonId}/staff-roles-by-user/${params.userId}`)
}