import './styles.css';

import * as actions from 'stores/actions';

import { Wrapper } from 'components'
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AuthAPI, UsersAPI } from 'stores/apis';

import { Loading } from 'components/loading';
import { envConfigs } from 'configs/env.config';
import i18next from 'i18next';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import gateway from 'stores/apis/gateway';
import UserModel from 'stores/models/user.models';
import { localStorageUtils } from 'utils';
import { LocalStorageValueTypes } from 'utils/localStorage';
import { validateEmail, validatePhone } from 'utils/string';

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}

interface InputState {
  loading: boolean
  name: string
  phone: string
  email: string
  password: string
  confirmPassword: string
  error: any
  lang: Language
}

const SignUp = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { refCode } = useParams()
  const [searchParams] = useSearchParams()

  const [state, setState] = useState<InputState>({
    loading: false,
    name: searchParams.get('name') ?? '',
    phone: searchParams.get('phone') ?? '',
    email: searchParams.get('email') ?? '',
    password: '',
    confirmPassword: '',
    error: '',
    lang: Language.English,
  })
  const dispatch = useDispatch()
  const handleStateChange = (value: string | string[] | boolean, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const saveUser = (data: any) => {
    dispatch({ type: actions.SET_USER, payload: new UserModel(data) })
    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserData, JSON.stringify(data))

    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, data.Language)
    // i18next.changeLanguage(data?.Language)
  }

  const saveRefCode = () => {
    if (refCode) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.SalonRefCode, refCode)
    }
  }

  const authLogin = async () => {
    // get user by token
    const token = localStorageUtils.getLocalStorage(LocalStorageValueTypes.AccessToken)
    if (!token) return false

    gateway.init(JSON.parse(token))
    const response: any = await UsersAPI.getUserProfile()
    const { data } = response

    if (data?.isSuccess) {
      dispatch({ type: actions.GET_TIMEZONES_REQUEST })
      saveUser(data?.data)
    }

    return data?.isSuccess
  }

  const checkAuthorized = async () => {
    const isLogin = await authLogin()

    if (isLogin) {
      window.location.href = `${envConfigs.WEB_URL}`
    }
  }

  useEffect(() => {
    saveRefCode()
    checkAuthorized()
  }, [])

  const onSignUp = async () => {
    handleStateChange('', 'error')
    handleStateChange(true, 'loading')
    const { name, phone, email, password, confirmPassword } = state

    if (!validateEmail(email)) {
      handleStateChange('Invalid email format.', 'error')
      handleStateChange(false, 'loading')

      return
    }

    if (!validatePhone(phone)) {
      handleStateChange('Invalid phone number.', 'error')
      handleStateChange(false, 'loading')

      return
    }

    if (password.trim().length < 6) {
      handleStateChange('Password must be at least 6 characters.', 'error')
      handleStateChange(false, 'loading')

      return
    } else if (password !== confirmPassword) {
      handleStateChange('Password and confirm password do not match.', 'error')
      handleStateChange(false, 'loading')

      return
    }

    handleStateChange(true, 'loading')
    const response: any = await AuthAPI.signup({
      name,
      email,
      phone,
      password
    })

    if (response?.data?.status === 200 && response?.data?.data?.access_token) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.AccessToken, JSON.stringify(response?.data?.data?.access_token))
      if (refCode) return navigate(`/${refCode}`)
      
      return navigate(`/${refCode}`)
    } else if (response?.data?.data?.message) {
      handleStateChange(response?.data?.data?.message, 'error')
    }
    handleStateChange(false, 'loading')
  }


  const onChangeInput = (e: any) => {
    const { name, value } = e.target
    handleStateChange(value, name)
  }

  const onSwitchingLanguage = () => {
    const newLang = state.lang === 'vi' ? 'en' : 'vi'
    handleStateChange(newLang, 'lang')
    i18next.changeLanguage(newLang)
    moment.locale(newLang)
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, newLang)
  }

  const disabled = !state.name || !state.email || !state.phone || !state.password || !state.confirmPassword || state.loading

  return (
    <div className="container">
      {state.loading && <Loading />}
      {/* <Link to='#' className="languageSection" onClick={onSwitchingLanguage}>
        {state.lang === 'en' ? <span className={`flag-icon flag-icon-gb`}></span> : <span className={`flag-icon flag-icon-vn`}></span>}
      </Link> */}
      <div className="content">
        <div className="login-page">
          <div className="header-section">
            <p className="txtText">{t('welcome_back')}</p>
          </div>

          <div className="error text-red text-center">
            {state.error}
          </div>

          <form className="form-login" method="POST" onSubmit={props.handleSubmit}>
            <div className="form-group">
              <input type="text" className="form-control" name="name" placeholder="Name" value={state.name} onChange={onChangeInput} />
            </div>

            <div className="form-group">
              <input type="text" className="form-control" name="phone" placeholder="Phone" value={state.phone} onChange={onChangeInput} />
            </div>

            <div className="form-group">
              <input type="email" className="form-control" name="email" placeholder="Email" value={state.email} onChange={onChangeInput} />
            </div>

            <div className="form-group">
              <input type="password" className="form-control" name="password" placeholder={t('password')} value={state.password} onChange={onChangeInput} />
            </div>

            <div className="form-group">
              <input type="password" className="form-control" name="confirmPassword" placeholder={t('Confirm password')} value={state.confirmPassword} onChange={onChangeInput} />
            </div>

            <div className="form-group text-center">
              <button type="button" className={`btn btn-login ${disabled ? 'btn-disabled' : ''}`} onClick={disabled ? undefined : onSignUp}>{t('Sign up')}</button>
            </div>

            <div className="login">
              <Link to={`/login`} className="login-text">{t('Do you already have an account?')} <b>Login</b></Link>
            </div>
          </form>
        </div>
      </div >
    </div >
  )
}

export default SignUp
