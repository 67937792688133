import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { NotificationsAPI } from 'stores/apis';
import NotificationsModel from 'stores/models/notifications.models';

export function* getNotificationsWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(NotificationsAPI.getNotifications, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_NOTIFICATIONS_SUCCESS, payload: response.data.data.map((v: any) => new NotificationsModel(v)) })
    } else {
      yield put({ type: actions.GET_NOTIFICATIONS_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_NOTIFICATIONS_FAILED })
  }
}

function* getNotificationsWatcher() {
  yield takeLatest(actions.GET_NOTIFICATIONS, getNotificationsWorker)
}

export default getNotificationsWatcher