export default {
  english: "English",
  vietnamese: "Vietnamese",
  hi: "Hi",
  logout: "Logout",
  welcome_back: "Welcome back",
  login_with_your_account: "Login with your account",
  password: "Password",
  forgot_password: "Forgot password",
  login: "Login",
  forgot_password_description: "Enter your email to receive the OTP",
  forgot_password_send_otp: "Send OTP to email",
  forgot_password_check_mail: "Please check the OTP in your mailbox.",
  forgot_password_expires: "OTP code will expire in 10 minutes",
  resend: "Resend in",
  enter_new_password: "Enter new password",
  reset_password: "Reset password",
  salon: "Salon",
  no_salon: "No salons",
  booking: "Booking",
  today: "Today",
  upcoming: "Upcoming",
  complete: "Complete",
  cancelled: "Cancelled",
  pending: "Pending",
  confirmed: "Confirmed",
  checked_in: "Checked In",
  check_out: "Check out",
  done: "Done",
  reject: "Reject",
  status: "Status",
  schedule: "Schedule",
  services: "Services",
  add_extra_service: "Add Extra Service",
  add_service: "Add services",
  sub_total: "Sub total",
  surcharge: "Surcharge",
  promotion: "Promotion",
  total: "Total",
  notes: "Notes",
  surcharge_promotion: "Surcharge promotion",
  comments: "Comments",
  edit: "Edit",
  delete: "Delete",
  choose_technician: "Choose technician",
  choose_technician_for_all_services:
    "Please choose technician for all services",
  promotion_must_be_less_than: "Promotion must be less than 100%",
  promotion_must_be_less_than_sub_total:
    "Promotion value must be less than Sub total",
  reject_successfully: "Rejected booking successfully!",
  confirm_successfully: "Confirmed booking successfully!",
  check_in_successfully: "Check in booking successfully!",
  check_out_successfully: "Check out booking successfully!",
  update_booking_status_successfully: "Update booking status successfully!",
  promotion_must_be_number: "Promotion must be a number",
  surcharge_must_be_number: "Surcharge must be a number",
  are_you_sure_logout: "Are you sure you want to logout?",
  cancel: "Cancel",
  add_extra_service: "Add extra service",
  name: "Name",
  price: "Price",
  duration: "Duration",
  technician: "Technician",
  choose_technician: "Choose technician",
  close: "Close",
  confirm: "Confirm",
  choose_service: "Choose service",
  choose_schedule: "Choose schedule",
  are_you_want_to_this_booking:
    "Are you sure you want to {{status}} this booking?",
  are_you_want_to_update_status_booking:
    "Are you sure you want update status booking?",
  check_in: "check in",
  check_out: "check out",
  cancelled_bookings: "Cancelled bookings",
  complete_bookings: "Complete bookings",
  upcoming_bookings: "Upcoming bookings",
  today_bookings: "Today bookings",
  waiting_for_confirm: "Waiting for confirm",
  waiting_for_check_in: "Waiting for check in",
  waiting_for_check_out: "Waiting for check out",
  notifications: "Notifications",
  change_language: "Change language",
  english: "English",
  vietnamese: "Vietnamese",
  phone: "Phone",
  address: "Address",
  about: "About",
  language: "Language",
  booking_now: "Booking now",
  time: "Time",
  booking_successfully: "Create booking successfully",
  go_detail: "View detail",
  back: "Back",
  pick_date: "Pick a Date",
  pick_time: "Pick a Time",
  salon_closed: "Salon closed today",
  no_services_selected: "No services selected",
  no_technician_selected: "No technician selected",
  price_ref_only: "Prices are for reference only",
  no_services: "No services",
  no_technicians: "No technicians",
  booking_code: "Booking code",
  rating: "Rating",
};
