import { AvatarColors } from 'constants/colors.constants'
import StaffModel from 'stores/models/staff.models'
import useStyles from './styles'

interface Props {
  avatarUrl?: any
  technician: StaffModel | undefined
  style?: any
  onPress?: () => void
}

export const TechnicianAvatar = (props: Props) => {
  const styles = useStyles()
  const { avatarUrl, technician, style, onPress } = props

  if (!avatarUrl) {
    const color: any = AvatarColors[technician?.avatarColorIdx?.toString() ?? '7']

    return (
      <div className={`${styles.image} ${styles.empty} ${style}`} style={{ backgroundColor: 'white', border: `1px solid ${color}` }} onClick={onPress}>
        <span className={`${styles.txtChar}`} style={{ color: color, fontSize: (style?.width ?? 40) * 0.48 }}>{technician?.name?.slice(0, 1)?.toUpperCase()}</span>
      </div>
    )
  }

  return (
    <div className={`${styles.image} ${style}`} onClick={onPress}>
      <img src={avatarUrl} width="100%" height="100%" style={{ borderRadius: '8px' }} />
    </div >
  )
}
