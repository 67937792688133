import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { StaffAPI } from 'stores/apis';
import StaffModel from 'stores/models/staff.models';

export function* getAdminsWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(StaffAPI.getStaffs, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_ADMINS_SUCCESS, payload: response.data.data.map((v: any) => new StaffModel(v)) })
    } else {
      yield put({ type: actions.GET_ADMINS_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_ADMINS_FAILED })
  }
}

function* getAdminsWatcher() {
  yield takeLatest(actions.GET_ADMINS, getAdminsWorker)
}

export default getAdminsWatcher