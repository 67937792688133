import images from 'assets';
import { envConfigs } from 'configs';

const API_SERVER = envConfigs.SERVER_URL

export enum Levels {
  SuperAdmin = 1,
  Admin = 2,
  Manager = 3,
  Supervisor = 4,
  Other = 9
}

export const LevelNames: any = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Quản lý dự án',
  4: 'Giám sát',
  9: 'Khác'
}

export default class UserModel {
  public id!: number
  public name!: string
  public phone!: string
  public email!: string
  public level!: Levels
  public code!: string
  public raw!: string
  public thumbnail!: string
  public avatarColorIdx!: number

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.phone = data.phone
      this.email = data.email
      this.level = data.level
      this.code = data.code
      this.raw = data.raw
      this.thumbnail = data.thumbnail
      this.avatarColorIdx = data.avatar_color_idx
    }
  }

  public get levelName(): string {
    return LevelNames[this.level];
  }

  public getUrl(): string {
    return `${envConfigs.IMAGE_LINK}/users/`
  }

  public get avatarUrl(): any {
    return this.thumbnail
      ? `${envConfigs.IMAGE_LINK}/users/${this.thumbnail}`
      : images.userAvatarDefault()
  }
}