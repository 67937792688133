export default {
  english: "Tiếng Anh",
  vietnamese: "Tiếng Việt",
  hi: "Xin chào",
  logout: "Đăng xuất",
  welcome_back: "Chào mừng trở lại",
  login_with_your_account: "Đăng nhập với tài khoản của bạn",
  password: "Mật khẩu",
  forgot_password: "Quên mật khẩu",
  login: "Đăng nhập",
  forgot_password_description: "Nhập email của bạn để nhận OTP",
  forgot_password_send_otp: "Gửi OTP đến email",
  forgot_password_check_mail: "Vui lòng kiểm tra OTP trong hộp thư của bạn.",
  forgot_password_expires: "Mã OTP sẽ hết hạn sau 10 phút",
  resend: "Gửi lại trong",
  enter_new_password: "Nhập mật khẩu mới",
  reset_password: "Đặt lại mật khẩu",
  salon: "Salon",
  no_salon: "Không có dữ liệu",
  booking: "Đặt lịch",
  today: "Hôm nay",
  upcoming: "Sắp tới",
  complete: "Hoàn thành",
  cancelled: "Đã hủy",
  pending: "Chờ duyệt",
  confirmed: "Đã xác nhận",
  checked_in: "Check in",
  check_out: "Thanh toán",
  done: "Hoàn thành",
  reject: "Từ chối",
  status: "Trạng thái",
  schedule: "Lịch trình",
  services: "Dịch vụ",
  add_extra_service: "Thêm dịch vụ bổ sung",
  add_service: "Thêm dịch vụ",
  sub_total: "Tổng phí",
  surcharge: "Phụ phí",
  promotion: "Khuyến mãi",
  total: "Tổng cộng",
  notes: "Ghi chú",
  surcharge_promotion: "Khuyến mãi phụ phí",
  comments: "Bình luận",
  edit: "Chỉnh sửa",
  delete: "Xóa",
  choose_technician: "Chọn kỹ thuật viên",
  choose_technician_for_all_services:
    "Vui lòng chọn kỹ thuật viên cho tất cả dịch vụ",
  promotion_must_be_less_than: "Khuyến mại phải nhỏ hơn 100%",
  promotion_must_be_less_than_sub_total:
    "Giá trị khuyến mại phải nhỏ hơn Tổng số phụ",
  reject_successfully: "Đã từ chối đặt chỗ thành công!",
  confirm_successfully: "Đã xác nhận đặt chỗ thành công!",
  check_in_successfully: "Khách hàng đã check in thành công!",
  check_out_successfully: "Thanh toán thành công!",
  update_booking_status_successfully: "Cập nhật trạng thái đặt chỗ thành công!",
  promotion_must_be_number: "Khuyến mãi phải là một số",
  surcharge_must_be_number: "Phụ phí phải là một số",
  are_you_sure_logout: "Bạn có muốn đăng xuất không?",
  cancel: "Hủy",
  add_extra_service: "Thêm dịch vụ bổ sung",
  name: "Tên",
  price: "Giá",
  duration: "Thời gian",
  technician: "Kỹ thuật viên",
  choose_technician: "Chọn kỹ thuật viên",
  close: "Đóng",
  confirm: "Xác nhận",
  choose_service: "Chọn dịch vụ",
  choose_schedule: "Chọn thời gian",
  are_you_want_to_this_booking: "Bạn chắc chắn muốn {{status}} booking này?",
  are_you_want_to_update_status_booking:
    "Bạn có chắc chắn muốn cập nhật trạng thái booking không?",
  check_in: "check in",
  check_out: "check out",
  cancelled_bookings: "Booking bị hủy",
  complete_bookings: "Booking hoàn thành",
  upcoming_bookings: "Booking sắp tới",
  today_bookings: "Booking hôm nay",
  waiting_for_confirm: "Đang chờ xác nhận",
  waiting_for_check_in: "Đang chờ check in",
  waiting_for_check_out: "Đang chờ check out",
  notifications: "Thông báo",
  change_language: "Đổi ngôn ngữ",
  english: "Tiếng Anh",
  vietnamese: "Tiếng Việt",
  phone: "Điện thoại",
  address: "Địa chỉ",
  about: "Thông tin",
  language: "Ngôn ngữ",
  booking_now: "Tạo lịch hẹn ngay",
  time: "Thời gian",
  booking_successfully: "Tạo lịch hẹn thành công",
  go_detail: "Xem chi tiết",
  back: "Quay lại",
  pick_date: "Chọn một ngày",
  pick_time: "Chọn thời gian",
  salon_closed: "Salon đóng cửa hôm nay",
  no_services_selected: "Chưa có dịch vụ nào được chọn",
  no_technician_selected: "Chưa có kỹ thuật viên nào được chọn",
  price_ref_only: "Giá chỉ mang tính chất tham khảo",
  no_services: "Không có dịch vụ để chọn",
  no_technicians: "Không có kỹ thuật viên để chọn",
  booking_code: "Mã đơn hàng",
  rating: "Danh Gia",
};
