import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { StaffAPI } from 'stores/apis';
import { AxiosResponse } from 'axios';

export function* getStaffRoleWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(StaffAPI.getStaffRole, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_STAFF_ROLE_SUCCESS, payload: response.data.data })
    } else {
      yield put({ type: actions.GET_STAFF_ROLE_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_STAFF_ROLE_FAILED })
  }
}

function* getStaffRoleWatcher() {
  yield takeLatest(actions.GET_STAFF_ROLE, getStaffRoleWorker)
}

export default getStaffRoleWatcher