import { createUseStyles } from "react-jss";

const useStyles: any = createUseStyles({
  modal: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    maxHeight: "80vh",
    width: "400px",
    "@media (max-width: 768px)": {
      width: "400px",
    },
    "@media (max-width: 480px)": {
      width: "320px",
    },
  },

  confirmContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "20px",
    color: "#2245a9",
    alignItems: "center",
  },

  buttonCancel: {
    display: "flex",
    marginTop: "20px",
    padding: "10px 20px",
    fontWeight: "700",
    borderRadius: "7px",
    color: "#2245a9",
    backgroundColor: "#fff",
    border: "1px solid #2245a9",
    outline: "none",
    cursor: "pointer",
  },

  // technician Modal List
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "700",
    color: "#2245a9",
    fontSize: "20px",
  },

  modalList: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },

  stars: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },

  textarea: {
    height: "150px",
    border: "1px solid rgb(204, 204,204)",
    borderRadius: "5px",
  },

  modalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "20px",
  },

  modalButton: {
    display: "flex",
    padding: "8px 12px",
    fontWeight: "700",
    borderRadius: "10px",
    color: "#fff",
    backgroundColor: "#2245a9",
    border: "none",
    outline: "none",
    cursor: "pointer",
    marginLeft: "15px",
  },

  modalButtonClose: {
    display: "flex",
    padding: "8px 12px",
    fontWeight: "700",
    borderRadius: "10px",
    color: "#2245a9",
    backgroundColor: "#fff",
    border: "1px solid #2245a9",
    outline: "none",
    cursor: "pointer",
  },
});

export default useStyles;
