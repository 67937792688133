import gateway, { APIMethod } from "./gateway"

import { envConfigs } from "configs"

const API_SERVER = envConfigs.SERVER_URL

export const login = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/login`, params)
}

export const signup = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/signup`, params)
}

export const sendForgetPasswordEmailOtp = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/users/send-reset-password-mail`, params)
}

export const checkEmailOtp = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/users/check-user-otp`, params)
}

export const refreshToken = (params: any) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/users/refresh-token`, params)
}

export const resetPassword = (params: any) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/users/reset-user-password`, params)
}

