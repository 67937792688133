export default class CategoriesModel {
  public id!: number
  public salonId!: number
  public name!: string
  public description!: string
  public createdAt!: string
  public updatedAt!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.salonId = data.salon_id
      this.name = data.name
      this.description = data.description
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
    }
  }
}