
import { useSelector } from "react-redux";

import { envConfigs } from "configs";
import i18next from 'i18next';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { BsListOl } from "react-icons/bs";
import { IoBookOutline, IoCalendarOutline, IoHomeOutline, IoNotificationsOutline } from 'react-icons/io5';
import { ReduxState } from "stores/models";
import { localStorageUtils } from "utils";
import { LocalStorageValueTypes } from 'utils/localStorage';
import './styles.css';

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}
interface State {
  lang: Language
}

const Menu = () => {
  const refCode = localStorageUtils.getLocalStorage(LocalStorageValueTypes.SalonRefCode)

  const { breadcrumb, user } = useSelector((state: ReduxState) => ({
    breadcrumb: state.app.breadcrumb,
    user: state.users.user
  }))

  const [state, setState] = useState<State>({
    lang: Language.English,
  })

  const handleStateChange = (value: any, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  if (!user) return null

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    console.log("language: ", lang)
    if (lang) {
      i18next.changeLanguage(lang)
      handleStateChange(lang, 'lang')
    }
  }, [])

  const onSwitchingLanguage = () => {
    const newLang = state.lang === 'vi' ? 'en' : 'vi'
    handleStateChange(newLang, 'lang')
    i18next.changeLanguage(newLang)
    moment.locale(newLang)
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, newLang)
  }

  const showBackButton = envConfigs.WEB_URL + '/' !== window.location.href;

  return (
    <nav>
      <ul className="menu">
        <li className={breadcrumb === 5 ? 'active' : ''}><a href={`/`}><BsListOl /></a></li>
        {refCode && <li className={breadcrumb === 1 ? 'active' : ''}><a href={`/${refCode}`}><IoHomeOutline /></a></li>}
        {refCode && <li className={breadcrumb === 2 ? 'active' : ''}><a href={`/${refCode}/booking-now`}><IoCalendarOutline /></a></li>}
        {refCode && <li className={breadcrumb === 3 ? 'active' : ''}><a href={`/${refCode}/bookings`}><IoBookOutline /></a></li>}
        {refCode && <li className={breadcrumb === 4 ? 'active' : ''}><a href={`/${refCode}/notifications`}><IoNotificationsOutline /></a></li>}
      </ul>
    </nav>
  )
}

export default Menu
