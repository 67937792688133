import { combineReducers } from 'redux';
import app from './app.reducers';
import bookings from './bookings.reducers';
import categories from './categories.reducers';
import notifications from './notifications.reducers';
import salons from './salons.reducers';
import services from './services.reducers';
import staffs from './staffs.reducers';
import users from './user.reducers';

export default combineReducers({
  app,
  salons,
  bookings,
  users,
  staffs,
  services,
  notifications,
  categories
})
