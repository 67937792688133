
import useStyles from './styles';

const ChooseTimeCard = (props: any) => {
  const styles = useStyles()
  const { time, selected, onSelect } = props


  return (
    <div className={`${styles.timeBox} ${time === selected && styles.timeBoxActive}`} onClick={() => onSelect(time)}>
      <div className={styles.timeNumber}>
        {time.format('H:mm')}
      </div>
    </div >
  )
}

export default ChooseTimeCard