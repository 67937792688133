import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './lang/en_US'
import viVN from './lang/vi_VN'

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  resources: {
    en_US: {
      translation: enUS
    },
    en: {
      translation: enUS
    },
    vi_VN: {
      translation: viVN
    },
    vi: {
      translation: viVN
    }
  }
})

export default i18next
