import useStyles from "./styles";
import { FaStar } from "react-icons/fa";
import { Colors } from "constants/colors.constants";

export const Stars = (props: any) => {
  const { rateValue, size, marginRight, onClick, onMouseOver, onMouseLeave } =
    props;
  const styles = useStyles();
  const stars = Array(5).fill(0);

  const handleStarClick = (index: number) => {
    if (onClick) {
      onClick(index + 1);
    }
  };

  const handleStarMouseOver = (index: number) => {
    if (onMouseOver) {
      onMouseOver(index + 1);
    }
  };

  const handleStarMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  return (
    <div className={styles.stars}>
      {stars.map((_, index) => (
        <FaStar
          key={index}
          size={size}
          style={{
            marginRight: marginRight,
            cursor: onClick ? "pointer" : "default",
          }}
          color={rateValue > index ? Colors.ORANGE : Colors.GRAY}
          onClick={() => handleStarClick(index)}
          onMouseOver={() => handleStarMouseOver(index)}
          onMouseLeave={handleStarMouseLeave}
        />
      ))}
    </div>
  );
};
