import { BsCheck2 } from 'react-icons/bs';
import { TechnicianAvatar } from 'components/technician-avatar';
import useStyles from './styles';

const ChooseTechnicianCard = (props: any) => {
  const styles = useStyles()
  const { technician, selected, onSelected } = props

  return (
    <div className={`${styles.techInfo} ${Number(selected?.id) === Number(technician?.id) ? styles.techInfoSelected : ''}`} onClick={() => onSelected(technician)}>
      <div className={styles.techAvatar}>
        <TechnicianAvatar avatarUrl={technician ? technician?.avatarUrl() : null} technician={technician} style={styles.techAvatar} />
      </div>
      <div className={styles.techName}>
        {technician?.name}
      </div>
      {
        Number(selected?.id) === Number(technician?.id) ? <span className={styles.checked}><BsCheck2 /></span> : null
      }
    </div>
  )
}

export default ChooseTechnicianCard