import * as actions from '../actions';

import { CategoriesState } from './../models/redux-state.models';

const initState: CategoriesState = {
  loading: false,
  categories: [],
}

const categories = (state = initState, action: any) => {
  switch (action.type) {

    case actions.GET_CATEGORIES:
      return {
        ...state,
        loading: true
      };
    case actions.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload
      };
    case actions.GET_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.LOG_OUT:
      return initState

    default:
      return state;
  }
};

export default categories