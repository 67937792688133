import * as actions from "stores/actions";

import { useDispatch } from "react-redux";
import { useState } from "react";
import useStyles from "./styles";
import BookingRateModel from "stores/models/booking-rate.models";
import { useTranslation } from "react-i18next";
import { FaStar } from "react-icons/fa";
import { Colors } from "constants/colors.constants";
import { modalRef } from "utils/refs";
import { BookingAPI } from "stores/apis";
import { Stars } from "components";

const Confirm = (props: any) => {
  const { message, bookingRate } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const stars = Array(5).fill(0);
  const onCloseModal = () => modalRef?.current?.close();

  return (
    <div className={styles.confirmContent}>
      <h4>{message}</h4>
      <div>
        <Stars rateValue={bookingRate} size={24} marginRight={20} />
      </div>
      <button className={styles.buttonCancel} onClick={onCloseModal}>
        {t("close")}
      </button>
    </div>
  );
};
const ModalBookingRate = (props: any) => {
  const { booking } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();
  const stars = Array(5).fill(0);

  const [state, setState] = useState({
    valueRating: 5,
    content: "",
    hoverRating: 5,
  });

  const onCloseModal = () => modalRef?.current?.close();

  const handleChangeState = (newState: any) =>
    setState((previousState) => ({ ...previousState, ...newState }));

  const onChangeInput = (e: any) => {
    handleChangeState((state.content = e.target.value));
  };

  const addBookingRate = async () => {
    const response = await BookingAPI.addBookingRate({
      bookingId: booking?.id,
      value: state.valueRating,
      content: state.content,
    });
    if (response.data.status === 200) {
      const newBookingRate = new BookingRateModel(response.data.data);
      booking.rate = newBookingRate;
      dispatch({
        type: actions.GET_BOOKING_DETAIL_SUCCESS,
        payload: booking,
      });
      onOpenConfirm("Thank you for the rating!", booking.rate.value);
    }
  };

  const updateBookingRate = async () => {
    const response = await BookingAPI.updateBookingRate({
      bookingId: booking?.id,
      rateId: booking?.rate.id,
      value: state.valueRating,
      content: state.content,
    });
    if (response.data.status === 200) {
      const newBookingRate = new BookingRateModel(response.data.data);
      booking.rate = newBookingRate;
      dispatch({
        type: actions.GET_BOOKING_DETAIL_SUCCESS,
        payload: booking,
      });
      onOpenConfirm("Update Rating Successfully!", booking.rate.value);
    }
  };

  const onConfirm = async () => {
    onCloseModal();
    if (booking?.rate.value == null) {
      addBookingRate();
    } else {
      updateBookingRate();
    }
  };

  const onOpenConfirm = (message: string, bookingRate: number) => {
    modalRef?.current?.open({
      content: <Confirm message={message} bookingRate={bookingRate} />,
    });
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>{t("rating")}</div>

      <div className={styles.modalList}>
        <div className={styles.stars}>
          <Stars
            rateValue={state.valueRating}
            size={26}
            marginRight={10}
            onClick={(value: any) =>
              handleChangeState((state.valueRating = value))
            }
            onMouseOver={(value: any) =>
              handleChangeState((state.hoverRating = value))
            }
            onMouseLeave={() => handleChangeState((state.hoverRating = 0))}
          />
        </div>
        <textarea
          className={styles.textarea}
          placeholder="What's your feedback?"
          value={state.content}
          onChange={onChangeInput}
        ></textarea>
      </div>

      <div className={styles.modalFooter}>
        <button
          className={styles.modalButtonClose}
          onClick={() => onCloseModal()}
        >
          <span>{t("close")}</span>
        </button>

        <button className={styles.modalButton} onClick={() => onConfirm()}>
          <span>{t("confirm")}</span>
        </button>
      </div>
    </div>
  );
};

export default ModalBookingRate;
