import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  wrapper: {
    paddingTop: '120px'
  },

  notAuthorizedNum: {
    color: '#fd7e14',
    fontSize: '80px',
    textAlign: 'center'
  },

  notAuthorizedText: {
    color: '#666',
    fontSize: '14px',
    textAlign: 'center',
    marginTop: '10px',
  },

  containers: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    maxHeight: '-webkit-fill-available',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px',
    padding: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    '@media (max-width: 768px)': {
      // height: '-webkit-fill-available',
    },
  },

  content: {
    overflow: 'auto',
    height: '100%',
    padding: '0 20px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },

  }
})

export default useStyles
