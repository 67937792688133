import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  f1: {
    flex: 1,
  },

  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0 20px',
  },

  sectionImages: {
    display: 'flex',
    flexDirection: 'column',
  },

  avatar: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
  },

  avatarImage: {
    position: 'absolute',
    top: '-150%',
    width: '20%',
    borderRadius: '50%',
    '@media (max-width: 900px)': {
      top: '-35px',
      width: '70px',
      height: '70px',
    },
  },

  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
  },

  rowSalonName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  salonName: {
    fontSize: '18px',
    fontWeight: 'bold',
    flex: 1,
  },

  bookingNow: {
    padding: '6px 16px',
    background: '#2245a9',
    color: '#ffffff',
    borderRadius: '20px',
  },

  banner: {
    width: '100%',
  },

  bannerImage: {
    width: '100%',
    borderRadius: '12px',
  },

  rowTitle: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0 0',
    fontWeight: '700',
    color: '#9b9b9b',
    flex: 1,
  },

  titleLabel: {
    marginLeft: '5px',
    textTransform: 'uppercase',
  },

  rowValue: {
    marginLeft: '22px',
    color: '#2245a9',
  },

  rowContent: {
    marginLeft: '22px',
    color: '#3C3C3E',
  },

  changeLanguageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  changeLanguage: {
    marginLeft: '10px',
    color: '#2245a9'
  },

  titleChangeLanguage: {
    color: 'red',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 'bold',
  },

  langueBtnGroup: {
    display: 'flex',
    paddingTop: '15px',
  },

  btnLanguage: {
    color: '#ffffff',
    padding: '6px 16px',
    background: '#2245a9',
    borderRadius: ' 20px',
  },

  mL: {
    marginLeft: '10px',
  },

  rowLogout: {
    color: '#ff0000',
    cursor: 'pointer',
  },

  logout: {
    marginLeft: '5px',
    textTransform: 'uppercase',
  },

  //logout modal
  logoutBtns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '20px',
  },

  btnNo: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '2px 20px',
    backgroundColor: '#fff',
    fontWeight: '600'
  },

  btnYes: {
    border: '1px solid #ff0000',
    borderRadius: '5px',
    padding: '2px 20px',
    color: '#ff0000',
    fontWeight: '600',
    backgroundColor: '#fff',
  },
})

export default useStyles
