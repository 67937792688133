import { all } from 'redux-saga/effects'
import getBooking from './bookings/getBooking.sagas'
import getBookings from './bookings/getBookings.sagas'
import getCategories from './categories/getCategories.sagas'
import getComments from './comments/getComments.sagas'
import getNotifications from './notifications/getNotifications.sagas'
import getSalons from './salons/getSalons.sagas'
import getService from './services/getService.sagas'
import getServices from './services/getServices.sagas'
import getAdmins from './staffs/getAdmins.sagas'
import getStaff from './staffs/getStaff.sagas'
import getStaffRole from './staffs/getStaffRole.sagas'
import getTechnicians from './staffs/getTechnicians.sagas'
import getTimezones from './timezones/getTimezones.sagas'

function* rootSagas() {
  yield all([
    getSalons(),
    getBooking(),
    getBookings(),
    getTimezones(),

    getAdmins(),
    getTechnicians(),
    getStaff(),
    getStaffRole(),

    getService(),
    getServices(),

    getComments(),
    getNotifications(),

    getCategories(),
  ])
}

export default rootSagas
