export const GET_TECHNICIANS = 'GET_TECHNICIANS'
export const GET_TECHNICIANS_SUCCESS = 'GET_TECHNICIANS_SUCCESS'
export const GET_TECHNICIANS_FAILED = 'GET_TECHNICIANS_FAILED'

export const GET_ADMINS = 'GET_ADMINS'
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS'
export const GET_ADMINS_FAILED = 'GET_ADMINS_FAILED'

export const GET_STAFF = 'GET_STAFF'
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS'
export const GET_STAFF_FAILED = 'GET_STAFF_FAILED'

export const GET_STAFF_ROLE = 'GET_STAFF_ROLE'
export const GET_STAFF_ROLE_SUCCESS = 'GET_STAFF_ROLE_SUCCESS'
export const GET_STAFF_ROLE_FAILED = 'GET_STAFF_ROLE_FAILED'