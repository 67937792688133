import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from 'stores/models';
import useStyles from './styles';

const HeaderLogout = (props: any) => {
  const styles = useStyles()
  const { t }: any = useTranslation()

  const { salon, user } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    user: state.users.user,
  }))

  const onOpenAddress = () => {
    window.open(`https://maps.google.com/?q=${salon?.location?.latitude},${salon?.location?.longitude}`)
  }

  return (
    <div className={styles.sectionHeader}>
      <div className={styles.sectionImages}>
        <div className={styles.salonAvatar}>
          <img className={styles.salonImage} src={salon?.logoUrl} alt="avatar" />
        </div>
      </div>
      <div className={styles.sectionUserInfo}>
        <div className={styles.salonName}>
          {salon?.name}
        </div>
        <div className={styles.salonAddress} onClick={onOpenAddress}>
          {salon?.location?.address}
        </div>
        <div className={styles.salonPhone}>
          {salon?.phone}
        </div>
      </div>
    </div>
  )
}

export default HeaderLogout