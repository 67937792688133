import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  wrapper: {
    paddingTop: '120px',
    margin: '0 auto',
  },

  code: {
    color: '#fd7e14',
    fontSize: '80px',
    textAlign: 'center'
  },

  text: {
    color: '#666',
    textAlign: 'center',
    marginTop: '10px',
  }
})

export default useStyles
