import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    position: 'fixed',
    top: 0,
    left: 0,
    opacity: 0.5,
    zIndex: 990,
  },
})

export default useStyles
