import * as actions from '../actions';

import { SalonsState } from 'stores/models/redux-state.models';

const initState: SalonsState = {
  loading: false,
  salons: [],
  salon: null,
  salonEdit: null
}

const salons = (state = initState, action: any) => {
  switch (action.type) {

    case actions.SET_SALON_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case actions.GET_SALONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.GET_SALONS_SUCCESS:
      return {
        ...state,
        loading: false,
        salons: action.payload
      };
    case actions.GET_SALONS_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.SET_CURRENT_SALON:
      return {
        ...state,
        salon: action.payload
      };

    case actions.SET_SALON_EDIT:
      return {
        ...state,
        salonEdit: action.payload
      };

    case actions.LOG_OUT:
      return initState

    default:
      return state;
  }
};

export default salons