import * as actions from '../actions';

import { StaffsState } from '@stores/models/redux-state.models';

const initState: StaffsState = {
  loading: false,
  admins: [],
  technicians: [],
  technician: null,
  staffRole: null
}

const staffs = (state = initState, action: any) => {
  switch (action.type) {

    case actions.GET_ADMINS:
      return {
        ...state,
        loading: true
      };
    case actions.GET_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: action.payload
      };
    case actions.GET_ADMINS_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.GET_TECHNICIANS:
      return {
        ...state,
        loading: true
      };
    case actions.GET_TECHNICIANS_SUCCESS:
      return {
        ...state,
        loading: false,
        technicians: action.payload
      };
    case actions.GET_TECHNICIANS_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.GET_STAFF:
      return {
        ...state,
        loading: true
      };
    case actions.GET_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        technician: action.payload
      };
    case actions.GET_STAFF_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.GET_STAFF_ROLE:
      return {
        ...state,
        loading: true
      };
    case actions.GET_STAFF_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        staffRole: action.payload
      };
    case actions.GET_STAFF_ROLE_FAILED:
      return {
        ...state,
        loading: false
      };

    case actions.LOG_OUT:
      return initState

    default:
      return state;
  }
};

export default staffs