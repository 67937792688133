import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import BookingCommentModel from 'stores/models/booking-comment.models';
import { CommentAPI } from 'stores/apis';

export function* getCommentsWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(CommentAPI.getComments, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_BOOKING_COMMENTS_SUCCESS, payload: response.data?.data?.comments.map((v: any) => new BookingCommentModel(v)) })
    } else {
      yield put({ type: actions.GET_BOOKING_COMMENTS_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_BOOKING_COMMENTS_FAILED })
  }
}

function* getCommentsWatcher() {
  yield takeLatest(actions.GET_BOOKING_COMMENTS, getCommentsWorker)
}

export default getCommentsWatcher