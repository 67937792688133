import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { SalonAPI } from 'stores/apis';
import SalonModel from 'stores/models/salon.models';

export function* getSalonsWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(SalonAPI.getSalons, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_SALONS_SUCCESS, payload: response.data.data.map((v: any) => new SalonModel(v)) })
    } else {
      yield put({ type: actions.GET_SALONS_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_SALONS_FAILED })
  }
}

function* getSalonsWatcher() {
  yield takeLatest(actions.GET_SALONS_REQUEST, getSalonsWorker)
}

export default getSalonsWatcher