import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px'
  },

  title: {
    fontSize: '30px'
  },

  select: {
    padding: '5px 10px',
    borderColor: '#dfe3e9',
    borderRadius: '5px'
  },

  // salon card
  salonsList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  sectionListHeader: {
    display: 'flex',
    flexDirection: 'row',
  },

  sectionListHeaderTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },

  salonsListItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '15px',
    cursor: 'pointer',
  },

  salonLogo: {
    width: '90px',
    height: '90px',
    borderRadius: '15px',
  },

  salonLogoImg: {
    width: '90px',
    height: '90px',
    borderRadius: '15px',
  },

  salonInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    justifyContent: 'center',
  },

  salonCategory: {
    backgroundColor: '#EEC7B5',
    padding: '3px 10px',
    borderRadius: '5px',
  },

  salonName: {
    fontSize: '16px',
    fontWeight: 'bold',
  },

  salonAddress: {
    fontSize: '12px',
  },
})

export default useStyles
