import moment from "moment-timezone"

export const minutesToHours = (number: number) => {
    const hours = Math.floor(number / 60)
    const minutes = number % 60
    if (hours === 0) {
        return `${minutes}m`
    }
    return `${hours}h ${minutes}m`
}

export const formatDate = (date: any, format: any = 'DD/MM/YYYY') => {
    if (date) return moment(date).format(format)
    return ''
}

export const formatUtcDate = (date: any, format: any = 'DD/MM/YYYY') => {
    if (date) return moment(date).utc().format(format)
    return ''
}

export const formatDateTz = (date: any, tz: any, format: any = 'DD/MM/YYYY') => {
    if (date && tz) return moment.tz(date, tz).format(format)
    return ''
}

export const getStartOfDayTz = (tz: any, format: any = 'YYYY-MM-DD') => {
    if (tz) return moment().tz(tz).format(format)
    return ''
}

export const getEndOfDayTz = (tz: any, format: any = 'YYYY-MM-DD HH:mm:ss', endOf: any = 'day') => {
    if (tz) return moment().tz(tz).endOf(endOf).format(format)
    return ''
}

export const getStartOfDateTz = (date: any, tz: any, format: any = 'YYYY-MM-DD') => {
    if (date && tz) return moment.tz(date, tz).format(format)
    return ''
}

export const getEndOfDateTz = (date: any, tz: any, format: any = 'YYYY-MM-DD HH:mm:ss', endOf: any = 'day') => {
    if (date && tz) return moment.tz(date, tz).endOf(endOf).format(format)
    return ''
}

export const dateDiff = (date1: any, date2: any, tz: any, format: any = 'YYYY-MM-DD') => {
    if (date1 && date2 && tz) {
        const newDate1 = moment(date1).tz(tz)
        const newDate2 = moment(date2).tz(tz)
        return newDate1.diff(newDate2, format)
    }
    return ''
}

export const convertTzToUtc = (date: any, tz: any, format: any = 'YYYY-MM-DD HH:mm:ss') => {
    if (date && tz) {
        const str_date = moment(date).format('YYYY-MM-DD HH:mm:ss')
        return moment.tz(str_date, tz).utc().format(format)
    }
    return ''
}

export const convertUtcToTz = (date: any, tz: any, format: any = 'YYYY-MM-DD HH:mm:ss') => {
    // const str_date = moment(date).format('YYYY-MM-DD HH:mm:ss')
    if (date && tz) return moment.utc(date).tz(tz).format(format)
    return ''
}

export const strToMins = (t: any) => {
    const s = t.split(":");
    return Number(s[0]) * 60 + Number(s[1]);
}

export const minsToStr = (t: any) => {
    return Math.trunc(t / 60) + ':' + ('00' + t % 60).slice(-2);
}

export const subTime = (t1: any, t2: any) => {
    if (t1 && t2) {
        return minsToStr(strToMins(t1) - strToMins(t2))
    }
    return
}