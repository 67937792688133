import * as actions from 'stores/actions'

import { Route, Routes } from "react-router-dom"

import { envConfigs } from "configs"
import Bookings from 'containers/bookings'
import Booking from 'containers/bookings/booking-detail'
import UpsertBooking from 'containers/bookings/upsert-booking'
import NotFound from 'containers/not-found'
import Notifications from 'containers/notifications'
import SalonProfile from 'containers/salons/salon-profile'
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { SalonAPI, UsersAPI } from 'stores/apis'
import gateway from 'stores/apis/gateway'
import SalonModel from 'stores/models/salon.models'
import UserModel from 'stores/models/user.models'
import { localStorageUtils } from "utils"
import { LocalStorageValueTypes } from "utils/localStorage"
import Salons from './containers/salons/index'

function AuthenticatedApp() {
  const dispatch = useDispatch()

  const [refCode, setRefCode] = useState('');

  useEffect(() => {
    if (refCode) {
      getSalon(refCode)
    }
  }, [refCode])

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    if (lang) {
      // i18next.changeLanguage(lang)
    }

    checkAuthorized()
  }, [])

  const saveUser = (data: any) => {
    dispatch({ type: actions.SET_USER, payload: new UserModel(data) })
    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserData, JSON.stringify(data))

    // localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, data.Language)
    // i18next.changeLanguage(data?.Language)
  }

  const authLogin = async () => {
    // get user by token
    const token = localStorageUtils.getLocalStorage(LocalStorageValueTypes.AccessToken)
    if (!token) return false

    gateway.init(JSON.parse(token))
    const response: any = await UsersAPI.getUserProfile()
    const { data } = response

    if (data?.isSuccess) {
      dispatch({ type: actions.GET_TIMEZONES_REQUEST })
      saveUser(data?.data)
    }

    dispatch({ type: actions.SET_AUTH_LOADING, payload: false })
    return data?.isSuccess
  }

  const checkAuthorized = async () => {
    dispatch({ type: actions.SET_AUTH_LOADING, payload: true })
    const isLogin = await authLogin()

    if (!isLogin) {
      onLogout()
      window.location.href = `${envConfigs.WEB_URL}/login`
    }

    const refCode = localStorageUtils.getLocalStorage(LocalStorageValueTypes.SalonRefCode)
    if (refCode) {
      setRefCode(refCode)
    }

    dispatch({ type: actions.SET_AUTH_LOADING, payload: false })
  }

  const getSalon = async (refCode: any) => {
    const response: any = await SalonAPI.getSalonWeb(refCode)
    if (response?.data?.status === 200) {
      dispatch({ type: actions.SET_CURRENT_SALON, payload: new SalonModel(response.data.data) })
      dispatch({ type: actions.GET_TIMEZONES_REQUEST })
    }
  }
  const onLogout = async () => {
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.AccessToken)
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserData)
    window.location.href = `${envConfigs.WEB_URL}/logout.html`
  }

  return (
    <Routes>
      <Route path="/" element={<Salons setRefCode={setRefCode} />} />
      <Route path="/:refCode" element={<SalonProfile />} />
      {refCode && <Route path="/:refCode/bookings/*" element={<Bookings />} />}
      {refCode && <Route path="/:refCode/notifications/*" element={<Notifications />} />}
      {refCode && <Route path="/:refCode/booking-now/*" element={<UpsertBooking />} />}
      {refCode && <Route path="/:refCode/bookings/:bookingId/*" element={<Booking />} />}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AuthenticatedApp