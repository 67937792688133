import './App.css';
import './locale';

import { Route, Routes } from 'react-router-dom';

import SignUp from 'containers/auth/sign-up';
import Authenticated from './Authenticated';
import ForgotPassword from './containers/auth/forgot-password';
import Login from './containers/auth/login';
import NotFound from './containers/not-found';


function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/login/:refCode" element={<Login />} />
      <Route path="/sign-up/*" element={<SignUp />} />
      <Route path="/sign-up/:refCode" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/*" element={<Authenticated />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
