export default class TimezoneModel {
	id!: number
	name!: string
	timezone!: string
	value!: number
	createdAt!: string

	constructor(data: any = {}) {
		if (data) {            
			this.id = data.id
			this.name = data.name
			this.timezone = data.timezone
			this.value = data.value
			this.createdAt = data.created_at
		}       
	}
}