import gateway, { APIMethod, parseFormData } from "./gateway"

import { envConfigs } from 'configs'

const API_SERVER = envConfigs.SERVER_URL

export const getServices = (params: any) => {
  const { salonId, ...query } = params
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/${params.salonId}/services`, query)
}

export const createService = (salonId: number, params: any, files: any) => {
  const formData: any = parseFormData(params)
  if (files?.uri) {
    formData.append('files', files)
  }

  return gateway.send(APIMethod.POST, `${API_SERVER}/salons/${salonId}/services`, formData, { 'Content-Type': 'multipart/form-data' })
}

export const getService = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/${params.salonId}/services/${params.serviceId}`)
}

export const updateService = (salonId: number, id: number, data: any, files: any) => {
  const formData: any = parseFormData(data)
  if (files?.uri) {
    formData.append('files', files)
  }

  return gateway.send(APIMethod.PUT, `${API_SERVER}/salons/${salonId}/services/${id}`, formData, { 'Content-Type': 'multipart/form-data' })
}

export const deleteService = (salonId: number, id: number) => {
  return gateway.send(APIMethod.DELETE, `${API_SERVER}/salons/${salonId}/services/${id}`)
}