import gateway, { APIMethod } from "./gateway"

import { envConfigs } from 'configs'

const API_SERVER = envConfigs.SERVER_URL

export const getCategories = (params: any) => {
  const { salonId, ...query } = params
  return gateway.send(APIMethod.GET, `${API_SERVER}/salons/${params.salonId}/categories`, query)
}

export const createCategories = (salonId: number, params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/salons/${salonId}/categories`, params)
}

export const updateCategories = (salonId: number, id: number, data: any) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/salons/${salonId}/categories/${id}`, data)
}

export const deleteCategories = (salonId: number, id: number) => {
  return gateway.send(APIMethod.DELETE, `${API_SERVER}/salons/${salonId}/categories/${id}`)
}