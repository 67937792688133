import { useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import ChooseServicesCard from "../choose-service-card";
import useStyles from './styles';

const Collapsible = (props: any) => {
    const styles = useStyles()

    const { row, selected, onSelected } = props
    const [show, setShow] = useState(true);

    const onToggle = () => {
        setShow(!show);
    };

    return (
        <div className={styles.rowGroupService}>
            <div onClick={onToggle} className={styles.groupTitle}>
                <span className={styles.groupTitleName}>{row?.category?.name}</span>
                {show && <span className={styles.groupTitleIcon}><AiOutlineCaretDown /></span>}
                {!show && <span className={styles.groupTitleIcon}><AiOutlineCaretUp /></span>}
            </div>
            {show &&
                row.data.length > 0 && row.data.map((service: any) => {
                    return <ChooseServicesCard key={service.id} service={service} selected={selected} onSelected={onSelected} />
                })
            }
        </div>
    );
}

export default Collapsible
