export const GET_BOOKINGS_REQUEST = 'GET_BOOKINGS_REQUEST'
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS'
export const GET_BOOKINGS_FAILED = 'GET_BOOKINGS_FAILED'

export const GET_BOOKING_DETAIL = 'GET_BOOKING_DETAIL'
export const GET_BOOKING_DETAIL_SUCCESS = 'GET_BOOKING_DETAIL_SUCCESS'
export const GET_BOOKING_DETAIL_FAILED = 'GET_BOOKING_DETAIL_FAILED'

export const GET_BOOKING_STATICS = 'GET_BOOKING_STATICS'
export const GET_BOOKING_STATICS_SUCCESS = 'GET_BOOKING_STATICS_SUCCESS'
export const GET_BOOKING_STATICS_FAILED = 'GET_BOOKING_STATICS_FAILED'


export const GET_TODAY_BOOKINGS_REQUEST = 'GET_TODAY_BOOKINGS_REQUEST'
export const GET_TODAY_BOOKINGS_SUCCESS = 'GET_TODAY_BOOKINGS_SUCCESS'
export const GET_TODAY_BOOKINGS_FAILED = 'GET_TODAY_BOOKINGS_FAILED'

export const GET_TODAY_BOOKING_STATICS = 'GET_TODAY_BOOKING_STATICS'
export const GET_TODAY_BOOKING_STATICS_SUCCESS = 'GET_TODAY_BOOKING_STATICS_SUCCESS'
export const GET_TODAY_BOOKING_STATICS_FAILED = 'GET_TODAY_BOOKING_STATICS_FAILED'

export const GET_PENDING_BOOKINGS_REQUEST = 'GET_PENDING_BOOKINGS_REQUEST'
export const GET_PENDING_BOOKINGS_SUCCESS = 'GET_PENDING_BOOKINGS_SUCCESS'
export const GET_PENDING_BOOKINGS_FAILED = 'GET_PENDING_BOOKINGS_FAILED'

export const GET_CUSTOMER_BOOKINGS_REQUEST = 'GET_CUSTOMER_BOOKINGS_REQUEST'
export const GET_CUSTOMER_BOOKINGS_SUCCESS = 'GET_CUSTOMER_BOOKINGS_SUCCESS'
export const GET_CUSTOMER_BOOKINGS_FAILED = 'GET_CUSTOMER_BOOKINGS_FAILED'