// eslint-disable-next-line no-use-before-define

import './pagination.css';

import * as actions from 'stores/actions';

import { Loading, Wrapper } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from 'assets/';
import EmptyState from 'components/empty-state';
import HeaderLogout from 'components/header-logout';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'stores/models';
import { localStorageUtils } from 'utils/index';
import { LocalStorageValueTypes } from 'utils/localStorage';
import NotificationCard from './components/notification-card';
import useStyles from './styles';

const Notifications = () => {
  const dispatch = useDispatch()
  const { t }: any = useTranslation()
  const styles = useStyles()
  const navigate: any = useNavigate()
  const limit = 10

  const { refCode } = useParams()

  const { salon, notifications, user } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    notifications: state.notifications.notifications,
    user: state.users.user,
  }))

  const [state, setState] = useState({
    loading: false,
  })
  const handleStateChange = (newState: any) => setState(prevState => ({ ...prevState, ...newState }))

  useEffect(() => {
    if (refCode) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.SalonRefCode, refCode)
    }
  }, [refCode])

  useEffect(() => {
    document.title = salon?.name ? salon.name : `KAY CLINIC`
  }, [salon])

  useEffect(() => {
    dispatch({ type: actions.SET_MENU, payload: 4 })
  }, [])

  useEffect(() => {
    if (user && refCode) {
      dispatch({ type: actions.GET_NOTIFICATIONS, payload: { salonId: salon?.id }, })
    }
  }, [user, refCode])

  return (
    <Wrapper>
      {state.loading && <Loading />}
      <HeaderLogout />

      <div className={styles.sectionList}>
        <div className={styles.sectionListHeader}>
          <div className={styles.sectionListHeaderTitle}>
            <p>{t('notifications')}</p>
          </div>
        </div>

        <div className={styles.bookingList}>
          {notifications.length > 0 ?
            <>
              {notifications?.map((notification: any) => (
                <NotificationCard key={notification.id} item={notification} />
              ))}
            </>
            :
            <EmptyState imgUrl={images.bookingEmpty()} text="No notifications" />
          }
        </div>
      </div>
    </Wrapper>
  )
}

export default Notifications
