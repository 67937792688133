import gateway, { APIMethod, parseFormData } from "./gateway"

import { envConfigs } from 'configs'

const API_SERVER = envConfigs.SERVER_URL

export const getComments = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/bookings/${params?.bookingId}/comments`, params)
}

export const addComment = (data: any, files: any) => {
  const { bookingId, ...params } = data
  const formData: any = parseFormData(params)

  files.forEach((f: any) => {
    if (f?.name) {
      formData.append('files', f)
    }
  })

  return gateway.send(APIMethod.POST, `${API_SERVER}/bookings/${bookingId}/comments`, formData, { 'Content-Type': 'multipart/form-data' })
}

export const updateComment = (params: any, files: any) => {
  const { bookingId, commentId, ...query } = params
  const formData: any = parseFormData(query)
  files.forEach((f: any) => {
    if (f?.name) {
      formData.append('files', f)
    }
  })

  return gateway.send(APIMethod.PUT, `${API_SERVER}/bookings/${bookingId}/comments/${commentId}`, formData, { 'Content-Type': 'multipart/form-data' })
}

export const deleteComment = (bookingId: number, commentId: number) => {
  return gateway.send(APIMethod.DELETE, `${API_SERVER}/bookings/${bookingId}/comments/${commentId}`)
}