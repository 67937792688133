import * as actions from 'stores/actions';

import { call, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { ServiceAPI } from 'stores/apis';
import ServiceModel from 'stores/models/service.models';

export function* getServicesWorker(action: any) {
  try {
    const response: AxiosResponse<any> | undefined = yield call(ServiceAPI.getServices, action.payload)
    if (response?.data?.status === 200) {
      yield put({ type: actions.GET_SERVICES_SUCCESS, payload: response.data.data.map((v: any) => new ServiceModel(v)) })
    } else {
      yield put({ type: actions.GET_SERVICES_FAILED })
    }
  } catch (error) {
    yield put({ type: actions.GET_SERVICES_FAILED })
  }
}

function* getServicesWatcher() {
  yield takeLatest(actions.GET_SERVICES, getServicesWorker)
}

export default getServicesWatcher