
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from './styles';

const NotificationCard = (props: any) => {
  const navigate: any = useNavigate()
  const { refCode } = useParams()
  const styles = useStyles()

  const { item } = props

  const goInfo = () => {
    const record = item.data ? JSON.parse(item.data) : {}
    console.log("record", record)
    navigate(`/${refCode}/bookings/${record?.bookingId}`)
  }

  return (
    <div className={styles.viewInfo} onClick={goInfo}>
      <div className={styles.txtFullName}>{item?.title}</div>
      <div className={styles.txtDate}>{item?.timestamp ? moment(item.timestamp * 1000).format('DD/MM/YYYY') : ''}</div>
      <div className={styles.txtPhone}>{item?.body}</div>
    </div>
  )
}

export default NotificationCard