const { location: { host } } = window

const production = {
  ENV: 'production',
  SERVER_URL: 'https://api.locaworld.net',
  WEB_URL: 'https://pri.locaworld.net',
  IMAGE_LINK: 'https://locas.s3.ap-southeast-1.amazonaws.com/prod'
}

const uat = {
  ENV: 'uat',
  SERVER_URL: 'https://stg-api.locaworld.net',
  WEB_URL: 'https://stg-pri.locaworld.net',
  IMAGE_LINK: 'https://locas.s3.ap-southeast-1.amazonaws.com/prod'
}

const dev = {
  ENV: 'dev',
  SERVER_URL: 'https://stg-api.locaworld.net',
  WEB_URL: 'http://localhost:3000',
  IMAGE_LINK: 'https://locas.s3.ap-southeast-1.amazonaws.com/prod'
}

export const envConfigs = host.indexOf('stg-pri.locaworld.net') >= 0 ? uat : (host.indexOf('pri.locaworld.net') >= 0 ? production : dev)
