import * as actions from 'stores/actions'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import images from 'assets'
import { Wrapper } from 'components'
import { envConfigs } from 'configs/env.config'
import i18next from 'i18next'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IoCallOutline, IoInformationCircleOutline, IoLocationOutline, IoLogOutOutline, IoMailOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { ReduxState } from 'stores/models'
import { localStorageUtils } from 'utils/index'
import { LocalStorageValueTypes } from 'utils/localStorage'
import { modalRef } from 'utils/refs'
import useStyles from './styles'

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}

interface State {
  lang: Language
}

const LogoutModalContent = ({ t, styles }: any) => {
  const dispatch = useDispatch()
  const { refCode } = useParams()

  const logout = () => {
    dispatch({ type: actions.LOG_OUT })
    localStorageUtils.clear()
    window.location.href = `${envConfigs.WEB_URL}/login`
  }

  const onCloseModal = () => modalRef?.current?.close()

  return (
    <div>
      <h6>{t('are_you_sure_logout')}</h6>
      <div className={styles.logoutBtns}>
        <button onClick={onCloseModal} className={styles.btnNo}>
          {t('cancel')}
        </button>
        <button onClick={logout} className={styles.btnYes}>
          {t('logout')}
        </button>
      </div>
    </div>
  )
}

const ChangeLanguageModal = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { onChangeLanguage } = props

  return (
    <div className={styles.changeLanguageContainer}>
      <div className={styles.titleChangeLanguage}>{t('change_language')}</div>
      <div className={styles.langueBtnGroup}>
        <span onClick={() => onChangeLanguage(Language.English)} className={styles.btnLanguage}>
          {t('english')}
        </span>
        <span onClick={() => onChangeLanguage(Language.Vietnamese)} className={`${styles.btnLanguage} ${styles.mL}`}>
          {t('vietnamese')}
        </span>
      </div>
    </div>
  )
}

const SalonProfile = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate: any = useNavigate()
  const styles = useStyles()
  const { refCode } = useParams()
  console.log("refCode 86", refCode)

  const limit = 10

  const [state, setState] = useState<State>({
    lang: Language.Vietnamese,
  })
  const handleStateChange = (value: any, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const { salon, user } = useSelector((state: ReduxState) => ({
    salon: state.salons.salon,
    user: state.users.user,
  }))

  useEffect(() => {
    if (refCode) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.SalonRefCode, refCode)
    }
  }, [refCode])

  useEffect(() => {
    document.title = salon?.name ? salon.name : `KAY CLINIC`
  }, [salon])

  useEffect(() => {
    dispatch({ type: actions.SET_MENU, payload: 1 })
  }, [])

  const bookingNow = () => {
    navigate(`/${refCode}/booking-now`)
  }

  const onOpenChangeLanguageModal = () => {
    modalRef?.current?.open({
      content: <ChangeLanguageModal onChangeLanguage={onChangeLanguage} />,
    })
  }

  const onChangeLanguage = (language: any) => {
    handleStateChange(language, 'lang')
    i18next.changeLanguage(language)
    moment.locale(language)
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, language)
    modalRef?.current?.close();
  }

  const onOpenLogoutModal = () => {
    modalRef?.current?.open({
      content: <LogoutModalContent t={t} styles={styles} />,
    })
  }

  if (!salon) return null

  return (
    <Wrapper>
      <div className={styles.content}>

        <div className={styles.sectionHeader}>
          <div className={styles.sectionImages}>
            <div className={styles.banner}>
              <img className={styles.bannerImage} src={salon?.bannerUrl} alt="banner" />
            </div>

            <div className={styles.avatar}>
              <img className={styles.avatarImage} src={salon?.logoUrl ?? images.userAvatarDefault()} alt="logo" />
            </div>
          </div>
        </div>

        <div className={styles.sectionContent}>
          <div className={styles.rowSalonName}>
            <div className={styles.salonName}>
              {salon?.name}
            </div>
            <div className={styles.bookingNow} onClick={bookingNow}>
              <a className={styles.booking}>{t('booking_now')}</a>
            </div>
          </div>

          <div className={styles.rowTitle}>
            <IoCallOutline size={18} />
            <div className={styles.titleLabel}>
              {t('phone')}
            </div>
          </div>
          <div className={styles.rowValue}>
            {salon?.phone}
          </div>

          <div className={styles.rowTitle}>
            <IoMailOutline size={18} />
            <div className={styles.titleLabel}>
              Email
            </div>
          </div>
          <div className={styles.rowValue}>
            {salon?.email}
          </div>

          <div className={styles.rowTitle}>
            <IoLocationOutline size={18} />
            <div className={styles.titleLabel}>
              {t('address')}
            </div>
          </div>
          <div className={styles.rowValue}>
            {salon?.location?.address}
          </div>

          <div className={styles.rowTitle}>
            <IoInformationCircleOutline size={18} />
            <div className={styles.titleLabel}>
              {t('about')}
            </div>
          </div>
          <div className={styles.rowContent}>
            {salon?.description}
          </div>

          <div className={styles.rowTitle}>
            <IoInformationCircleOutline size={18} />
            <div className={styles.titleLabel}>
              {t('language')}
            </div>
          </div>
          <div className={styles.rowContent}>
            {state.lang === 'en' ? <span className={`flag-icon flag-icon-gb`}></span> : <span className={`flag-icon flag-icon-vn`}></span>}
            <span className={styles.changeLanguage} onClick={onOpenChangeLanguageModal}>{t('change_language')}</span>
          </div>
          <div className={`${styles.rowTitle} ${styles.rowLogout}`} onClick={onOpenLogoutModal}>
            <IoLogOutOutline size={18} color={'##ff0000'} />
            <div className={styles.logout}>
              {t('logout')}
            </div>
          </div>
        </div>
      </div>
    </Wrapper >
  )
}

export default SalonProfile
