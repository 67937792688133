import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  f1: {
    flex: 1,
  },

  flexRow: {
    flexDirection: 'row',
  },

  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '15px',
    borderBottom: '1px solid #dfe3e9',
  },

  rowSalonName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  salonInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: '10px'
  },

  salonInfo2: {
    padding: '15px 0',
  },

  salonName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },

  salonPhone: {
    display: 'flex',
    color: '#2245a9',
    fontWeight: '600',
  },

  salonAddress: {
    display: 'flex',
  },

  salonAvatar: {
    width: '72px',
    height: '72px',
    borderRadius: '12px',
  },

  salonImage: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
  },

  //section list
  sectionList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 0 20px',
  },

  contentRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0 0',
  },

  contentRowInline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  rowLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 0 5px',
    fontWeight: '700',
    color: '#858585',
    flex: 1,
    textTransform: 'uppercase',
  },

  label: {
    marginLeft: '10px',
    flex: 1,
  },

  labelButton: {
    textTransform: 'none',
    color: '#2245a9',
  },

  rowItemContent: {
    color: '#3C3C3E',
    fontWeight: '700',
    marginLeft: '28px',
  },

  rowItemStatus: {
    color: '#2245a9',
    fontWeight: '500',
    marginLeft: '28px',
  },

  rowContentRight: {
    color: '#3C3C3E',
    fontWeight: '700',
    textAlign: 'right',
  },

  //booking service card
  bookingServices: {
    display: 'flex',
    flexDirection: 'column',
  },

  serviceRowName: {
    display: 'flex',
    flexDirection: 'row',
  },

  serviceName: {
    fontWeight: '700',
    color: '#3C3C3E',
  },

  serviceInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },

  techInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  technicianCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60px',
    // height: '90px',
  },

  techAvatar: {
    width: '60px',
    height: '60px',
    borderRadius: '12px',
  },

  techAvatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
  },

  techName: {
    fontWeight: '700',
    color: '#3C3C3E',
  },

  serviceDuration: {
    fontWeight: '700',
    color: '#3C3C3E',
  },

  addService: {
    display: 'flex',
    color: '#2245a9',
    fontWeight: '700',
  },

  addExtraService: {
    display: 'flex',
    color: '#2245a9',
    fontWeight: '700',
    marginRight: '1rem',
  },

  // technician Modal List
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 0 20px',
    fontWeight: '700',
    color: '#2245a9',
    borderBottom: '1px solid #2245a9',
    marginBottom: '20px',
    fontSize: '18px',
  },

  modalList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '700px',
  },

  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px 20px',
    borderTop: '1px solid #2245a9',
    marginTop: '20px',
  },

  btnBooking: {
    padding: '10px 20px',
    fontWeight: '700',
    borderRadius: '20px',
    color: '#fff',
    backgroundColor: '#2245a9',
    border: 'none',
    outline: 'none',
    width: '50%',
    textAlign: 'center',
  },

  btnDisabled: {
    backgroundColor: '#DFDFDF',
    color: '#3C3C3E',
    cursor: 'not-allowed !important',
  },

  modalButtonConfirm: {
    display: 'flex',
    padding: '10px 20px',
    fontWeight: '700',
    borderRadius: '20px',
    color: '#fff',
    backgroundColor: '#49AE6B',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginLeft: '15px',
  },

  //footer
  bookingFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #dfe3e9',
    padding: '15px 0',
    marginTop: '20px',
  },

  //promotion
  rowCheckBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '15px',
    fontWeight: '700',
  },

  checkBox: {
    '&:checked': {
      backgroundColor: '#2245a9 !important',
      borderColor: '#2245a9 !important',
    },
  },

  groupPromotion: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  promotion: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '45px !important',
  },

  chargeDiscount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '10px 15px',
    fontWeight: '700',
    cursor: 'pointer',
    position: 'absolute',
    right: '0',
    borderLeft: '1px solid #dfe3e9',
  },

  //confirm modal
  confirmContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px',
  },

  //group button
  groupButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },

  buttonConfirm: {
    display: 'flex',
    padding: '10px 20px',
    fontWeight: '700',
    borderRadius: '7px',
    color: '#fff',
    backgroundColor: '#2245a9',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginLeft: '1.8rem',
  },

  buttonCancel: {
    display: 'flex',
    padding: '10px 20px',
    fontWeight: '700',
    borderRadius: '7px',
    color: '#2245a9',
    backgroundColor: '#fff',
    border: '1px solid #2245a9',
    outline: 'none',
    cursor: 'pointer',
  },

  //promotionValueOptions
  promotionValueOptionTitle: {
    display: 'flex',
    color: '#2245a9',
    fontWeight: '700',
  },
  promotionValueOptions: {
    display: 'flex',
    flexDirection: 'column',
  },

  promotionValueOption: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    borderBottom: '1px dashed #dfe3e9',
    cursor: 'pointer',
  },

  //comments
  comments: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
  },

  comment: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px dashed #dfe3e9',
    padding: '10px 0',
    alignItems: 'center',
    marginBottom: '10px',
  },

  commentAvatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    border: '1px dashed #dfe3e9',
  },

  commentAvatarImg: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },

  commentBody: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginLeft: '10px',
  },

  commentContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: '10px',
    marginLeft: '10px',
  },

  commentContentText: {
    backgroundColor: '#F3F6F6',
    padding: '15px',
    borderRadius: '10px',
    marginBottom: '10px',
  },

  commentText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '5px',
  },

  commentImages: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  commentImage: {
    width: '100px',
    borderRadius: '10px',
    marginRight: '10px',
  },

  commentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },

  commentAuthor: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    flex: 1,
  },

  commentDate: {
    display: 'flex',
    flexDirection: 'row',
    fontStyle: 'italic',
  },
  //chat
  messageInput: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: '20px',
  },

  fileInput: {
    display: 'none',
  },

  groupInput: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: '10px',
  },

  viewImages: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
  },

  imageItem: {
    display: 'flex',
    position: 'relative',
  },

  deleteImage: {
    display: 'flex',
    position: 'absolute',
    right: '10px',
    top: '-10px',
    borderRadius: '50%',
    border: '1px solid #b2b0b0',
    color: '#b2b0b0',
    cursor: 'pointer',
  },

  image: {
    width: '100px',
    padding: '5px',
    marginRight: '20px',
    borderRadius: '5px',
    border: '1px dashed #dfe3e9',
  },

  messageInputText: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    borderRadius: '7px',
    border: '1px solid #dfe3e9',
    padding: '10px',
    outline: 'none',
    fontWeight: '700',
    color: '#3C3C3E',
  },

  rowDate: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },

  noSelected: {
    width: '60px',
    height: '60px',
  },

  textarea: {
    width: '100%',
    borderRadius: '10px',
    padding: '7px',
    backgroundColor: '#f8f8f8',
    resize: 'none',
    outline: '0px none transparent',
    '&:focus, &:active': {
      border: '1px solid #2245a9'
    }
  },
})

export default useStyles
