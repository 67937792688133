import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    // booking card
    bookingServices: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        borderRadius: '15px',
        backgroundColor: '#F3F6F6',
        marginBottom: '15px',
        marginTop: '7px',
    },

    f1: {
        flex: 1,
    },

    serviceRowName: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    serviceName: {
        fontSize: '16px',
        fontWeight: '700',
        color: '#3C3C3E',
    },

    serviceDuration: {
        fontSize: '12px',
    },

    deleteService: {
        display: 'flex',
        color: '#ff0000',
        cursor: 'pointer',
    },

    serviceInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginTop: '8px',
    },

    techInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },

    techAvatar: {
        width: '36px',
        height: '36px',
        borderRadius: '8px',
        marginRight: '10px',
    },

    techAvatarImg: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
    },

    techName: {
        color: '#3C3C3E',
        fontWeight: '400',
    },

    groupPrice: {
        textAlign: 'right'
    },

    price: {
        color: '#3C3C3E',
        fontWeight: '600'
    },

    refPrice: {
        color: '#3C3C3E',
        fontWeight: '400',
        textDecoration: 'line-through',
    },
})

export default useStyles