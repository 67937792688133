import useStyles from './styles'

const EmptyState = (props: any) => {
  const styles = useStyles()

  const { imgUrl, text, className, classNameImage } = props
  return (
    <div className={`${styles.viewEmpty} ${className}`}>
      <img className={`${styles.imgEmpty} ${classNameImage}`} src={imgUrl} />
      <h4 className={styles.txtEmpty}>{text}</h4>
    </div>
  )
}

export default EmptyState